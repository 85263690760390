<div class="navigation-title">
    <div class="back-button" (click)="backButton()">
        <fa-icon [icon]="faArrowCircleLeft" [size]="'xl'"></fa-icon>
    </div>
    <div class="title">{{currentTitle}}</div>
</div>
<div class="container-fluid withdraw-mobile-component px-0 pt-3 pb-5">
    <div class="main-witdraw-mobile">
        @if(loader) {
            <div class="spinner-container">
                <div class="spinner-border" style="margin: 0 auto;color: var(--primary-text-color);" role="status"></div>
            </div>
        }
        @if(showPin && !loader){
        <div class="container-fluid pin-component">
            <form class="mb-5">
                <div class="main-pin">
                    <div class="h2 text-white">
                        Masukan pin anda
                    </div>
                    <div class="pin-input my-3 my-md-4">
                        <input id="pin1" type="tel" maxlength="1" tabindex="1" autocomplete="off"
                            oninput="this.value = this.value.replace(/[^0-9]/g,'');" onfocus="this.select();"
                            (ngModelChange)="inputPIN(1)" [(ngModel)]="formPin.pina" autofocus
                            [ngModelOptions]="{ standalone: true }" class="pin-digit" />
                        <input id="pin2" type="tel" maxlength="1" tabindex="2" autocomplete="off"
                            oninput="this.value = this.value.replace(/[^0-9]/g,'');" onfocus="this.select();"
                            (ngModelChange)="inputPIN(2)" [(ngModel)]="formPin.pinb" [ngModelOptions]="{ standalone: true }"
                            class="pin-digit" />
                        <input id="pin3" type="tel" maxlength="1" tabindex="3" autocomplete="off"
                            oninput="this.value = this.value.replace(/[^0-9]/g,'');" onfocus="this.select();"
                            (ngModelChange)="inputPIN(3)" [(ngModel)]="formPin.pinc" [ngModelOptions]="{ standalone: true }"
                            class="pin-digit" />
                        <input id="pin4" type="tel" maxlength="1" tabindex="4" autocomplete="off"
                            oninput="this.value = this.value.replace(/[^0-9]/g,'');" onfocus="this.select();"
                            (ngModelChange)="inputPIN(4)" [(ngModel)]="formPin.pind" [ngModelOptions]="{ standalone: true }"
                            class="pin-digit" />
                        <input id="pin5" type="tel" maxlength="1" tabindex="5" autocomplete="off"
                            oninput="this.value = this.value.replace(/[^0-9]/g,'');" onfocus="this.select();"
                            (ngModelChange)="inputPIN(5)" [(ngModel)]="formPin.pine" [ngModelOptions]="{ standalone: true }"
                            class="pin-digit" />
                        <input id="pin6" type="tel" maxlength="1" tabindex="6" autocomplete="off"
                            oninput="this.value = this.value.replace(/[^0-9]/g,'');" onfocus="this.select();"
                            (ngModelChange)="inputPIN(6)" [(ngModel)]="formPin.pinf" [ngModelOptions]="{ standalone: true }"
                            class="pin-digit" />
                    </div>
        
                    <div class="d-md-flex justify-content-center gap-5">
                        <button type="button" class="btn btn-pinpage btn-signin" (click)="submitPIN()"
                            [disabled]="loadingSubmit">
                            Verifikasi
                        </button>
                    </div>
                </div>
            </form>
        </div>
        }
        @if(!showSelectPayment && !showCreateBank && !showError &&!loader && !showPin){
            <div class="row align-items-center main-withdraw mb-5">
                <div class="saldo-container">
                    <div class="sub-title">SALDO ANDA</div>
                    <div class="balance-value">{{balance | currency:' ' : 'symbol' : '1.0-0'}}</div>
                </div>
                <div class="select-payment-container">
                    <div class="input-select-payment">
                        <button class="btn btn-outline-primary w-100"
                            [ngClass]="disabledButton || !transactionConfig['allow_change_withdrawal_account']? 'pointer-off':''"
                            (click)="selectBankOption()">
                            @if(currentDataBankUser['system_bank']){
                            <span class="img">
                                <img [src]="currentDataBankUser['system_bank']['images']['small']"
                                    [alt]="currentDataBankUser['system_bank']['name']">
                            </span>
                            <span class="bank-name">{{currentDataBankUser['system_bank']['name']}}</span>
                            }
                            @if(transactionConfig['allow_change_withdrawal_account']){
                            <fa-icon [icon]="faCaretDown"></fa-icon>
                            }
                        </button>
                    </div>
                    <div class="input-no-rek">
                        <button class="btn btn-outline-primary w-100">
                            @if(currentDataBankUser){
                                <span class="bank-name">{{currentDataBankUser['account_number']}}</span>
                                <fa-icon class="icon-left" [icon]="faCreditCard" [size]="'lg'"></fa-icon>
                            }
                        </button>
                    </div>
                    <div class="input-user">
                        <button class="btn btn-outline-primary w-100">
                            @if(currentDataBankUser){
                                <span class="bank-name">{{currentDataBankUser['account_name']}}</span>
                                <fa-icon class="icon-left" [icon]="faUser" [size]="'lg'"></fa-icon>
                            }
                        </button>
                    </div>
                    <div class="input-value" [ngClass]="disabledButton? 'pointer-off':''">
                        
                        <input id="inputAmount" type="text" spellcheck="false"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        class="form-control"
                        [ngModel]="(inputAmount > 0 ? 'Rp '+ (inputAmount | currency:' ' : 'symbol' : '1.0-0'): '')"
                        (ngModelChange)="updateValueNominal($event)"
                        [placeholder]="'Rp '+(minWDAmount | currency:' ' : 'symbol' : '1.0-0')" name="inputAmount"
                        aria-label="Masukan Nominal" />
                        <label for="inputAmount" class="label-input-small">
                            <fa-icon class="icon-left" [icon]="faWallet" [size]="'lg'"></fa-icon>
                        </label>
                    </div>
                    <div class="text-uppercase border-0 outline-0 text-white w-100 btn-submit-withdraw" [ngClass]="disabledButton? 'pointer-off':''"
                        (click)="transactionConfig['pin_at_withdrawal'] && inputAmount >= transactionConfig['pin_min_withdrawal_amount'] ? checkPin() :withdrawBalance()">WITHDRAW</div>
                    <!-- @if(transactionConfig['show_livechat_withdrawal']){
                        <span class="text-chat">Hubungi Kami jika mengalami masalah saat melakukan withdraw, <a href="{{livechatURL}}"
                            target="_blank">Klik Disini</a></span>
                    } -->
                </div>
            </div>
        }
        @if(showSelectPayment && !showCreateBank && !showError && !loader && !showPin){
            <div class="page-change-payment">
                <div class="container-xxl m-auto">
                    <div class="row align-items-center container-change-payment py-5 pt-3 mb-5">
                        <div class="title-page-small h5">
                            {{paymentType === 'company' ? 'Pilih Rekening Tujuan':'Pilih Rekening Anda'}}
                        </div>
                        <div class="container-input-filter">
                            <div ngbDropdown class="position-relative filter-popularity" autoClose="true">
                                <button class="btn-dropdown px-4" (click)="openFilter(currentSelectPaymentType)">
                                    <fa-icon [icon]="faSliders"></fa-icon>
                                    <span>Filter</span>
                                </button>
            
                                @if(paymentType === 'company'){
                                <div ngbDropdownMenu class="dropdown-menu select-bank">
                                    <div class="d-flex flex-column gap-2">
                                        <label ngbDropdownItem for="paymentAll" class="d-flex gap-2"
                                            [class.active]="currentSelectPaymentType === 'all'"
                                            (click)="onSelectDropdownValue('all')">
                                            <!-- <input id="paymentAll" type="checkbox" [checked]="currentSelectPaymentType === 'all'" /> -->
                                            Tampilkan Semua
                                        </label>
                                        <label ngbDropdownItem for="paymentBank" class="d-flex gap-2"
                                            [class.active]="currentSelectPaymentType === 'bank'"
                                            (click)="onSelectDropdownValue('bank')">
                                            <!-- <input id="paymentBank" type="checkbox" [checked]="currentSelectPaymentType === 'bank'" /> -->
                                            Bank
                                        </label>
                                        <label ngbDropdownItem for="paymentEwallet" class="d-flex gap-2"
                                            [class.active]="currentSelectPaymentType === 'ewallet'"
                                            (click)="onSelectDropdownValue('ewallet')">
                                            <!-- <input id="paymentEwallet" type="checkbox" [checked]="currentSelectPaymentType === 'ewallet'" /> -->
                                            e-Wallet
                                        </label>
                                        <label ngbDropdownItem for="paymentCellular" class="d-flex gap-2"
                                            [class.active]="currentSelectPaymentType === 'cellular'"
                                            (click)="onSelectDropdownValue('cellular')">
                                            <!-- <input id="paymentEwallet" type="checkbox" [checked]="currentSelectPaymentType === 'ewallet'" /> -->
                                            Celular
                                        </label>
                                        <label ngbDropdownItem for="paymentQris" class="d-flex gap-2"
                                            [class.active]="currentSelectPaymentType === 'qris'"
                                            (click)="onSelectDropdownValue('qris')">
                                            <!-- <input id="paymentEwallet" type="checkbox" [checked]="currentSelectPaymentType === 'ewallet'" /> -->
                                            QRIS
                                        </label>
                                    </div>
                                </div>
                                } @else{
                                <div ngbDropdownMenu class="dropdown-menu select-bank">
                                    <div class="d-flex flex-column gap-2">
                                        <label ngbDropdownItem for="paymentAll" class="d-flex gap-2"
                                            [class.active]="currentSelectPaymentType === 'all'"
                                            (click)="onSelectDropdownValue('all')">
                                            <!-- <input id="paymentAll" type="checkbox" [checked]="currentSelectPaymentType === 'all'" /> -->
                                            Tampilkan Semua
                                        </label>
                                        <label ngbDropdownItem for="paymentBank" class="d-flex gap-2"
                                            [class.active]="currentSelectPaymentType === 'bank'"
                                            (click)="onSelectDropdownValue('bank')">
                                            <!-- <input id="paymentBank" type="checkbox" [checked]="currentSelectPaymentType === 'bank'" /> -->
                                            Bank
                                        </label>
                                        <label ngbDropdownItem for="paymentEwallet" class="d-flex gap-2"
                                            [class.active]="currentSelectPaymentType === 'ewallet'"
                                            (click)="onSelectDropdownValue('ewallet')">
                                            <!-- <input id="paymentEwallet" type="checkbox" [checked]="currentSelectPaymentType === 'ewallet'" /> -->
                                            e-Wallet
                                        </label>
                                    </div>
                                </div>
            
                                }
                            </div>
                            <div class="input-filter">
                                <input class="form-control" type="text" [(ngModel)]="searchBankInput" placeholder="Cari Rekening"
                                    (keyup)="onSearchDropdownValue($event)">
                            </div>
                        </div>
                        @if(paymentType === 'company'){
                        }@else{
                        <div class="container-list"
                            [ngClass]="paymentType !== 'company' && transactionConfig['allow_add_bank_account'] ? 'extra-padding':''">
                            <!-- <swiper-container #swiper appSwiper [config]="config" class="swiper-max-h align-items-center"> -->
                            <div class="container-list-slider">
                                @for (bank of searchedOptions; track bank;) {
                                <div class="swiper-item">
                                    <div class="container-payment-bar">
                                        @if(bank['qr_image'] || bank['system_bank']['code'] === 'qris'){
                                        <div class="payment-bar"
                                            [ngClass]="bank['_id'] === currentSelectDataBankUser['_id'] ? 'active':''"
                                            (click)="selectedBankUser(bank)">
                                            <div class="payment-logo">
                                                <div class="image-payment">
                                                    <img [src]="bank['system_bank']['images']['small']"
                                                        [alt]="bank['system_bank']['name']">
                                                </div>
                                                <!-- <span>QRIS</span> -->
                                            </div>
                                            <div class="payment-group">
                                                {{bank['account_name']}}
                                                <!-- <div class="payment-number">
                                                    -
                                                </div> -->
                                            </div>
                                        </div>
                                        }
                                        @if(!bank['qr_image'] && bank['system_bank']['code'] !== 'qris'){
                                        <div class="payment-bar"
                                            [ngClass]="bank['_id'] === currentSelectDataBankUser['_id'] ? 'active':''"
                                            (click)="selectedBankUser(bank)">
                                            <div class="payment-logo">
                                                <div class="image-payment">
                                                    <img [src]="bank['system_bank']['images']['small']"
                                                        [alt]="bank['system_bank']['name']">
                                                </div>
                                            </div>
                                            <div class="payment-group">
                                                {{bank['account_name']}}
                                                <div class="payment-number">
                                                    {{bank['account_number'] | splitNumber}}
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                                }
                            </div>
                            <!-- </swiper-container> -->
                        </div>
                        }
                        @if(paymentType !== 'company' && transactionConfig['allow_add_bank_account']){
                        <div class="container-buttons">
                            <div class="border-0 outline-0 text-white w-100 btn-create-rek" (click)="selectCreateBankOption()">
                                + Tambah Rekening
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        }
    </div>
    @if(showCreateBank && !showSelectPayment && !showError &&!loader && !showPin){
    <div class="page-create-payment">
        <div class="container-xxl m-auto">
            <div class="row align-items-center main-create-bank mb-5">
                <div class="create-payment-container">
                    <div class="input-select-payment">
                        <div ngbDropdown class="d-inline-block w-100">
                            <button type="button" class="btn btn-outline-primary w-100 btn-trigger" id="chooseBank"
                                ngbDropdownToggle (click)="openFilterCreateBank(currentSelectCreateBank)">
                                <!-- <fa-icon class="icon-left" [icon]="faMoneyCheckDollar" [size]="'lg'"></fa-icon> -->
                                @if(currentSelectCreateBank === 'bank'){
                                <span class="selected-title">BANK</span>
                                }@else {
                                <span class="selected-title">E-WALLET</span>
                                }
                            </button>
                            <div ngbDropdownMenu style="display: none;"></div>
                            <!-- <div ngbDropdownMenu class="w-100" aria-labelledby="chooseBank">
                                @if(listSelectCreateBank.length > 0){
                                <button ngbDropdownItem (click)="selectBankCreate('bank')">
                                    BANK
                                    @if(currentSelectCreateBank === 'bank'){
                                    <fa-icon class="icon-right" [icon]="faCircleCheck"></fa-icon>
                                    }
                                </button>
                                }
                                @if(listSelectCreateEwallet.length > 0){
                                <button ngbDropdownItem (click)="selectBankCreate('ewallet')">
                                    E-WALLET
                                    @if(currentSelectCreateBank === 'ewallet'){
                                    <fa-icon class="icon-right" [icon]="faCircleCheck"></fa-icon>
                                    }
                                </button>
                                }
                            </div> -->
                        </div>
                        <div ngbDropdown class="d-inline-block w-100">
                            <button type="button" class="btn btn-outline-primary w-100 btn-trigger" id="chooseBank"
                                ngbDropdownToggle (click)="openFilterSelectCreateBank(currentSelectedCreateBank)">
                                <span class="img">
                                    @if(currentSelectedCreateBank['images']){
                                    <img [src]="currentSelectedCreateBank['images']['small']"
                                        [alt]="currentSelectedCreateBank['name']">
                                    }
                                </span>
                                <span class="bank-name">{{currentSelectedCreateBank['name']}}</span>
                            </button>
                            <div ngbDropdownMenu style="display: none;"></div>
                            <!-- <div ngbDropdownMenu class="w-100" aria-labelledby="chooseBank">
                                <div class="w-100 search-create-bank">
                                    <input type="text" class="form-control" autocomplete="off"
                                        [(ngModel)]="inputSearchCreate" (keyup)="onSearchcreateValue($event)"
                                        placeholder="Search Bank.." name="searchBankInput" aria-label="Search Bank.." />
                                    <button type="button" class="btn">
                                        <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                                    </button>
                                </div>
                                @for (item of searchedCreateOptions; track item;) {
                                <button ngbDropdownItem (click)="selectedCreateBank(item)">
                                    <span class="img">
                                        <img [src]="item['images']['small']" [alt]="item['name']">
                                    </span>
                                    <span class="bank-name">{{item['name']}}</span>
                                    @if(currentSelectedCreateBank['_id'] === item['_id']){
                                    <fa-icon class="icon-right" [icon]="faCircleCheck"></fa-icon>
                                    }
                                </button>
                                }
                            </div> -->
                        </div>
                    </div>
                    <div class="input-no-rek">
                        <input id="userBankNum" type="text" spellcheck="false"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                            class="form-control" [(ngModel)]="inputUserBankNum" [placeholder]="'Nomor Rekening'"
                            name="userBankNum" aria-label="Nomor Rekening" />
                        <label for="userBankNum" class="label-input-small">
                            <fa-icon class="icon-left" [icon]="faCreditCard" [size]="'lg'"></fa-icon>
                        </label>
                    </div>
                    <div class="input-user">
    
                        <input id="userBankName" type="text" spellcheck="false" class="form-control"
                            [(ngModel)]="inputUserBankName" [disabled]="true" [placeholder]="'Nama Rekening'"
                            name="userBankName" aria-label="Nama Rekening" />
                        <label for="userBankName" class="label-input-small">
                            <fa-icon class="icon-left" [icon]="faUser" [size]="'lg'"></fa-icon>
                        </label>
                    </div>
                    <div class="text-uppercase border-0 outline-0 text-white w-100 btn-submit-create"
                        [ngClass]="disabledButton? 'pointer-off':''" (click)="createBankAcc()">Tambah Rekening</div>
                </div>
            </div>
        </div>
    </div>
    }
</div>