@if(showNotif){
<div class="error-notif"><fa-icon [icon]="faCircleInfo"></fa-icon>{{showNotifText}}</div>
}
@if(loader) {
<div class="spinner-container">
    <div class="spinner-border" style="margin: 0 auto;color: var(--primary-text-color);" role="status"></div>
</div>
}
@if(showPin && !loader){
<div class="container-fluid pin-component">
    <form class="mb-5">
        <div class="main-pin">
            <div class="h2 text-white">
                Masukan pin anda
            </div>
            <div class="pin-input my-3 my-md-4">
                <input id="pin1" type="tel" maxlength="1" tabindex="1" autocomplete="off"
                    oninput="this.value = this.value.replace(/[^0-9]/g,'');" onfocus="this.select();"
                    (ngModelChange)="inputPIN(1)" [(ngModel)]="formPin.pina" autofocus
                    [ngModelOptions]="{ standalone: true }" class="pin-digit" />
                <input id="pin2" type="tel" maxlength="1" tabindex="2" autocomplete="off"
                    oninput="this.value = this.value.replace(/[^0-9]/g,'');" onfocus="this.select();"
                    (ngModelChange)="inputPIN(2)" [(ngModel)]="formPin.pinb" [ngModelOptions]="{ standalone: true }"
                    class="pin-digit" />
                <input id="pin3" type="tel" maxlength="1" tabindex="3" autocomplete="off"
                    oninput="this.value = this.value.replace(/[^0-9]/g,'');" onfocus="this.select();"
                    (ngModelChange)="inputPIN(3)" [(ngModel)]="formPin.pinc" [ngModelOptions]="{ standalone: true }"
                    class="pin-digit" />
                <input id="pin4" type="tel" maxlength="1" tabindex="4" autocomplete="off"
                    oninput="this.value = this.value.replace(/[^0-9]/g,'');" onfocus="this.select();"
                    (ngModelChange)="inputPIN(4)" [(ngModel)]="formPin.pind" [ngModelOptions]="{ standalone: true }"
                    class="pin-digit" />
                <input id="pin5" type="tel" maxlength="1" tabindex="5" autocomplete="off"
                    oninput="this.value = this.value.replace(/[^0-9]/g,'');" onfocus="this.select();"
                    (ngModelChange)="inputPIN(5)" [(ngModel)]="formPin.pine" [ngModelOptions]="{ standalone: true }"
                    class="pin-digit" />
                <input id="pin6" type="tel" maxlength="1" tabindex="6" autocomplete="off"
                    oninput="this.value = this.value.replace(/[^0-9]/g,'');" onfocus="this.select();"
                    (ngModelChange)="inputPIN(6)" [(ngModel)]="formPin.pinf" [ngModelOptions]="{ standalone: true }"
                    class="pin-digit" />
            </div>

            <div class="d-md-flex justify-content-center gap-5">
                <button type="button" class="btn btn-pinpage btn-signin" (click)="submitPIN()"
                    [disabled]="loadingSubmit">
                    Verifikasi
                </button>
            </div>
        </div>
    </form>
</div>
}

@if(currentDataBankAdmin['system_bank'] && !loader){
@if(!showSelectPayment && !showCreateBank && !showError && !showPromo && !showPin){
<div class="page-deposit-manual">
    <div class="row manual-deposit container-manual-deposit-mobile d-flex mb-5">
        <div class="container-box-top">
            <!-- <div class="container-rek-anda">

                @if(currentDataBankAdmin['system_bank']){
                @if(currentDataBankAdmin['system_bank']['type'] === 3){
                <div class="sub-title">BUKTI TRANSFER</div>
                <div class="area-upload area-upload-cellular">
                    <div class="input">
                        <textarea name="catatan" placeholder="Masukkan Kode Bukti Transfer" type="text"></textarea>
                    </div>
                    <div class="upload-image">
                        <label class="label-upload" for="image-pembayaran" [ngClass]="disabledButton? 'pointer-off':''">
                            @if(base64ImageData === ''){
                            <fa-icon [icon]="faImages" [size]="'xl'"></fa-icon>
                            <span>Upload Bukti</span>
                            } @else {
                            <div class="container-base64">
                                <img src="{{base64ImageData}}">
                                <span class="name-file">{{imageFileName}}</span>
                            </div>
                            }
                        </label>
                        <input #imageInput style="display: none;" type="file" class="form-control" id="image-pembayaran"
                            name="image-pembayaran" placeholder="Upload bukti pembayaran"
                            (change)="onSelectFile($event)" />
                    </div>
                </div>
                }@else{
                <div class="sub-title">REKENING ANDA</div>
                <div class="bank-user">
                    <div class="image">
                        <img [src]="currentDataBankUser['system_bank']['images']['small']"
                            [alt]="currentDataBankUser['system_bank']['name']">
                    </div>
                    <div class="text-name">
                        <span class="name">{{currentDataBankUser['account_name']}}</span>
                        <span class="no-rek">{{currentDataBankUser['account_number'] | splitNumber}}</span>
                        <div class="btn btn-switch-bank-user" [ngClass]="disabledButton? 'pointer-off':''"
                            (click)="changePayment('user')">Ganti Rekening Pembayaran</div>
                    </div>
                </div>
                <div class="area-upload">
                    <div class="input">
                        <textarea [ngClass]="disabledButton? 'pointer-off':''" name="catatan" placeholder="Catatan..."
                            type="text"></textarea>
                    </div>
                    <div class="upload-image">
                        <label class="label-upload" for="image-pembayaran" [ngClass]="disabledButton? 'pointer-off':''">
                            @if(base64ImageData === ''){
                            <fa-icon [icon]="faImages" [size]="'xl'"></fa-icon>
                            <span>Upload Bukti</span>
                            } @else {
                            <div class="container-base64">
                                <img src="{{base64ImageData}}">
                                <span class="name-file">{{imageFileName}}</span>
                            </div>
                            }
                        </label>
                        <input #imageInput style="display: none;" type="file" class="form-control" id="image-pembayaran"
                            name="image-pembayaran" placeholder="Upload bukti pembayaran"
                            (change)="onSelectFile($event)" />
                    </div>
                </div>
                }
                }
            </div> -->
            <div class="container-rek-tujuan">
                <div class="sub-title">REKENING TUJUAN</div>
                <div class="bank-company">
                    @if(currentDataBankAdmin['system_bank']){
                        @if(currentDataBankAdmin['system_bank']['code'] !== 'qris' && currentDataBankAdmin['qr_image'] ===
                        null){
                            <div class="container-row-rek">
                                <div class="image">
                                    <img [src]="currentDataBankAdmin['system_bank']['images']['small']"
                                        [alt]="currentDataBankAdmin['system_bank']['name']">
                                </div>
                                @if(currentDataBankAdmin['system_bank']['type'] === 3){
                                <div class="text-name">
                                    <!-- <span class="name-placeholder">Nomor Handphone</span> -->
                                    <span class="no-rek">{{currentDataBankAdmin['account_number'] | splitNumber}}</span>
                                </div>
                                }@else{
                                <div class="text-name">
                                    <!-- <span class="name-placeholder">Nama Rekening</span> -->
                                    <span class="name">{{currentDataBankAdmin['account_name']}}</span>
                                    <!-- <span class="rek-placeholder">Nomor Rekening</span> -->
                                    <span class="no-rek">{{currentDataBankAdmin['account_number'] | splitNumber}}</span>
                                </div>
                                }
                            </div>
                            <div class="btn btn-switch-bank-company w-100" [ngClass]="disabledButton? 'pointer-off':''"
                                (click)="changePayment('company')">Ganti Metode Pembayaran</div>
                        }
                        @if(currentDataBankAdmin['system_bank']['code'] === 'qris' || currentDataBankAdmin['qr_image'] !==
                        null){
                            <div class="container-row-rek">
                                <div class="image-qris">
                                    <img #parentImage
                                        [src]="'../../../assets/orianna/images/bank/'+(currentDataBankAdmin | fileImageBank)+'?'+currentUnix"
                                        [alt]="currentDataBankAdmin | fileImageBank">
                                </div>
                                <div class="text-name">
                                    <span class="name-placeholder">Nama Rekening</span>
                                    <span class="name">{{currentDataBankAdmin['account_name']}}</span>
                                    <div class="download-qr">
                                        <div class="btn btn-download" (click)="saveImage(parentImage)">Download QR</div>
                                        <div class="btn btn-switch-bank-company w-100" [ngClass]="disabledButton? 'pointer-off':''"
                                            (click)="changePayment('company')">Ganti Metode Pembayaran</div>
                                    </div>
                                </div>
                            </div>
                        }
                    }
                </div>
            </div>
        </div>
        <div class="container-rek-anda">
            @if(currentDataBankAdmin['system_bank']){
                @if(currentDataBankAdmin['system_bank']['type'] === 3){
                <div class="sub-title">BUKTI TRANSFER</div>
                <div class="area-upload area-upload-cellular">
                    <div class="input">
                        <textarea [formControl]="noteDeposit" name="catatan" placeholder="Masukkan Kode Bukti Transfer" type="text"></textarea>
                    </div>
                    <div class="upload-image">
                        <label class="label-upload" for="image-pembayaran" [ngClass]="disabledButton? 'pointer-off':''">
                            @if(base64ImageData === ''){
                            <fa-icon [icon]="faImages" [size]="'xl'"></fa-icon>
                            <span>Upload Bukti</span>
                            } @else {
                            <div class="container-base64">
                                <img src="{{base64ImageData}}">
                                <span class="name-file">{{imageFileName}}</span>
                            </div>
                            }
                        </label>
                        <input #imageInput style="display: none;" type="file" class="form-control" id="image-pembayaran"
                            name="image-pembayaran" placeholder="Upload bukti pembayaran"
                            (change)="onSelectFile($event)" />
                    </div>
                </div>
                }@else{
                <div class="sub-title">REKENING ANDA</div>
                <div class="bank-user">
                    <div class="image">
                        <img [src]="currentDataBankUser['system_bank']['images']['small']"
                            [alt]="currentDataBankUser['system_bank']['name']">
                    </div>
                    <div class="text-name">
                        <span class="name">{{currentDataBankUser['account_name']}}</span>
                        <span class="no-rek">{{currentDataBankUser['account_number'] | splitNumber}}</span>
                    </div>
                    <div class="btn btn-switch-bank-user" [ngClass]="disabledButton? 'pointer-off':''"
                        (click)="changePayment('user')">Ganti</div>
                </div>
                <div class="area-upload">
                    <div class="input">
                        <textarea [formControl]="noteDeposit" [ngClass]="disabledButton? 'pointer-off':''" name="catatan" placeholder="Catatan..."
                            type="text"></textarea>
                    </div>
                    <div class="upload-image d-custom-height-none d-custom-height-normal-flex">
                        <label class="label-upload" for="image-pembayaran" [ngClass]="disabledButton? 'pointer-off':''">
                            @if(base64ImageData === ''){
                            <fa-icon [icon]="faImages" [size]="'xl'"></fa-icon>
                            <span class="ms-2">Upload Bukti</span>
                            } @else {
                            <div class="container-base64">
                                <img src="{{base64ImageData}}">
                                <span class="name-file">{{imageFileName}}</span>
                            </div>
                            }
                        </label>
                        <input #imageInput style="display: none;" type="file" class="form-control" id="image-pembayaran"
                            name="image-pembayaran" placeholder="Upload bukti pembayaran"
                            (change)="onSelectFile($event)" />
                    </div>
                    <div class="custom-layout-height">
                        <div class="upload-image d-custom-height-flex d-custom-height-normal-none">
                            <label class="label-upload" for="image-pembayaran" [ngClass]="disabledButton? 'pointer-off':''">
                                @if(base64ImageData === ''){
                                <fa-icon [icon]="faImages" [size]="'xl'"></fa-icon>
                                } @else {
                                <div class="container-base64">
                                    <img src="{{base64ImageData}}">
                                    <!-- <span class="name-file">{{imageFileName}}</span> -->
                                </div>
                                }
                            </label>
                            <input #imageInput style="display: none;" type="file" class="form-control" id="image-pembayaran"
                                name="image-pembayaran" placeholder="Upload bukti pembayaran"
                                (change)="onSelectFile($event)" />
                        </div>
                        <div class="choose-promo d-custom-height-flex d-custom-height-normal-none">
                            <div class="d-inline-block w-100">
                                @if (currentPromo['_id']) {
                                <div class="container-promo-bar active-promo" [ngClass]="disabledButton? 'pointer-off':''">
                                    <div class="area-click"
                                        (click)="!transactionConfig['allow_cellular_promotion'] && currentDataBankAdmin['system_bank']['type'] === 3 ? '' : openPromoSearch()">
                                        <span class="img-icon">
                                            <img src="../../../template/green/assets/images/voucher_applied.png">
                                        </span>
                                        <!-- <span class="text-status">Voucher Applied</span> -->
                                        <span class="text-name-promo">{{currentPromo['title']}}</span>
                                    </div>
                                    <fa-icon [icon]="faTrashCan" (click)="resetPromo()"></fa-icon>
                                </div>
                                } @else {
                                <div class="container-promo-bar" [ngClass]="disabledButton? 'pointer-off':''"
                                    (click)="!transactionConfig['allow_cellular_promotion'] && currentDataBankAdmin['system_bank']['type'] === 3 ? '' : openPromoSearch()">
                                    <span class="img-icon">
                                        <img src="../../../template/green/assets/images/voucher.png">
                                    </span>
                                    <!-- <span>Pilih Voucher</span> -->
                                    <!-- <fa-icon [icon]="faTrashCan"></fa-icon> -->
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                }
            }
            <div class="container-box-bottom">
                <div class="container-payment-details">
                    <div class="container-amount-promo">
                        <div class="container-input">
                            <div class="input-amount">
                                <label for="inputAmount" class="label-input-small"
                                    [ngClass]="disabledButton? 'pointer-off':''">Jumlah Deposit</label>
                                    <div class="container-form">
                                        <div class="prefix-currency">IDR</div>
                                        <input [ngClass]="disabledButton? 'pointer-off':''" id="inputAmount" type="text"
                                            spellcheck="false"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                            class="form-control" placeholder="10.000"
                                            [ngModel]="(inputAmount | currency:' ' : 'symbol' : '1.0-0')"
                                            (ngModelChange)="updateValueNominal($event)"
                                            [placeholder]="currentDataBankAdmin['system_bank'] ? currentDataBankAdmin['system_bank']['min_deposit_amount'] > 0 ? (currentDataBankAdmin['system_bank']['min_deposit_amount'] | currency:' ' : 'symbol' : '1.0-0') : (minDPAmount | currency:' ' : 'symbol' : '1.0-0') :''"
                                            name="inputAmount" aria-label="Masukan Nominal" />
                                    </div>
                            </div>
                        </div>
                        <div class="choose-promo d-custom-height-none d-custom-height-normal-flex">
                            <div class="d-inline-block w-100">
                                @if (currentPromo['_id']) {
                                <div class="container-promo-bar active-promo" [ngClass]="disabledButton? 'pointer-off':''">
                                    <div class="area-click"
                                        (click)="!transactionConfig['allow_cellular_promotion'] && currentDataBankAdmin['system_bank']['type'] === 3 ? '' : openPromoSearch()">
                                        <span class="img-icon">
                                            <img src="../../../template/green/assets/images/voucher_applied.png">
                                        </span>
                                        <!-- <span class="text-status">Voucher Applied</span> -->
                                        <span class="text-name-promo">{{currentPromo['title']}}</span>
                                    </div>
                                    <fa-icon [icon]="faTrashCan" (click)="resetPromo()"></fa-icon>
                                </div>
                                } @else {
                                <div class="container-promo-bar" [ngClass]="disabledButton? 'pointer-off':''"
                                    (click)="!transactionConfig['allow_cellular_promotion'] && currentDataBankAdmin['system_bank']['type'] === 3 ? '' : openPromoSearch()">
                                    <span class="img-icon">
                                        <img src="../../../template/green/assets/images/voucher.png">
                                    </span>
                                    <span class="text-status">Pilih Promo</span>
                                    <!-- <span>Pilih Voucher</span> -->
                                    <!-- <fa-icon [icon]="faTrashCan"></fa-icon> -->
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="container-payment-detail">
                        <div class="jumlah-deposit">
                            <span>Jumlah Deposit</span>
                            <span>Rp {{inputAmount ? (inputAmount | currency:' ' : 'symbol' : '1.0-0') : 0}}</span>
                        </div>
                        <div class="biaya-transaksi">
                            <span>Biaya Transaksi</span>
                            <span>{{currentDataBankAdmin['system_bank'] ?
                                currentDataBankAdmin['system_bank']['transaction_fee'] ||
                                currentDataBankAdmin['system_bank']['transaction_fee']? '- Rp '+(currentFee | currency:' ' :
                                'symbol' : '1.0-0'):'FREE' :''}}</span>
                        </div>
                        <div class="total-diterima">
                            <span>TOTAL DITERIMA</span>
                            <span>Rp {{inputAmount ? ((amountDepositNumber - currentFee < 0 ? 0:amountDepositNumber -
                                    currentFee) | currency:' ' : 'symbol' : '1.0-0' ) : 0}}</span>
                        </div>
                        <div class="text-uppercase border-0 outline-0 text-white w-100 btn-deposit-manual"
                            (click)="transactionConfig['pin_at_deposit'] && amountDepositNumber >= transactionConfig['pin_min_deposit_amount'] ? checkPin() : submitDeposit()"
                            [ngClass]="disabledButton? 'pointer-off':''">DEPOSIT</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
}
}
<!-- @else{
    <div class="page-change-payment">
        <div class="container-xxl m-auto">
            <div class="row align-items-center container-change-payment d-none d-md-flex py-5 mb-5">
                <div class="title-page-small h5">
                    {{paymentType === 'company' ? 'Pilih Rekening Tujuan':'Pilih Rekening Anda'}}
                </div>
                <div class="container-input-filter">
                    <div class="input-filter">
                        <input class="form-control" type="text" placeholder="Cari Rekening...">
                    </div>
                    <div ngbDropdown class="position-relative filter-popularity" autoClose="true">
                        <button class="btn-dropdown px-4" ngbDropdownToggle>
                            <fa-icon [icon]="faSliders"></fa-icon>
                            <span>Filter</span>
                        </button>
                        
                        <div ngbDropdownMenu class="dropdown-menu">
                            <div class="d-flex flex-column gap-2">
                                <div ngbDropdownItem class="d-flex gap-2">
                                    Tampilkan Semua
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                @if(paymentType === 'company'){
                    <div class="container-list">
                        <swiper-container appSwiper [config]="config" class="swiper-max-h align-items-center">
                            @for (bank of listSelectBankCompany; track bank;) {
                                <swiper-slide class="swiper-item">
                                    <div class="container-payment-bar">
                                        @if(bank['qr_image'] || bank['system_bank']['code'] === 'qris'){
                                            <div class="payment-bar" [ngClass]="bank['_id'] === currentDataBankAdmin['_id'] ? 'active':''" (click)="selectedBankCompany(bank)">
                                                <div class="payment-logo">
                                                    <div class="image-payment">
                                                        <img src="../../../../template/green/assets/images/qris.png">
                                                    </div>
                                                    <span>QRIS</span>
                                                </div>
                                                <div class="payment-group">
                                                    {{bank['account_name']}}
                                                </div>
                                                <div class="payment-number">
                                                    -
                                                </div>
                                            </div>
                                        }
                                        @if(!bank['qr_image'] && bank['system_bank']['code'] !== 'qris'){
                                            <div class="payment-bar" [ngClass]="bank['_id'] === currentDataBankAdmin['_id'] ? 'active':''" (click)="selectedBankCompany(bank)">
                                                <div class="payment-logo">
                                                    <div class="image-payment">
                                                        <img [src]="bank['system_bank']['images']['small']" [alt]="bank['system_bank']['name']">
                                                    </div>
                                                    <span>{{bank['system_bank']['name']}}</span>
                                                </div>
                                                <div class="payment-group">
                                                    {{bank['account_name']}}
                                                </div>
                                                <div class="payment-number">
                                                    {{bank['account_number'] | splitNumber}}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </swiper-slide>
                            }
                        </swiper-container>
                    </div>
                } @else {
                    <div class="container-list">
                        <swiper-container appSwiper [config]="config" class="swiper-max-h align-items-center">
                            @for (bank of listSelectBankUser; track bank;) {
                            <swiper-slide class="swiper-item">
                                <div class="container-payment-bar">
                                    @if(bank['qr_image'] || bank['system_bank']['code'] === 'qris'){
                                    <div class="payment-bar" [ngClass]="bank['_id'] === currentDataBankUser['_id'] ? 'active':''" (click)="selectedBankUser(bank)">
                                        <div class="payment-logo">
                                            <div class="image-payment">
                                                <img [src]="bank['system_bank']['images']['small']" [alt]="bank['system_bank']['name']">
                                            </div>
                                            <span>QRIS</span>
                                        </div>
                                        <div class="payment-group">
                                            {{bank['account_name']}}
                                        </div>
                                        <div class="payment-number">
                                            -
                                        </div>
                                    </div>
                                    }
                                    @if(!bank['qr_image'] && bank['system_bank']['code'] !== 'qris'){
                                    <div class="payment-bar" [ngClass]="bank['_id'] === currentDataBankUser['_id'] ? 'active':''" (click)="selectedBankUser(bank)">
                                        <div class="payment-logo">
                                            <div class="image-payment">
                                                <img [src]="bank['system_bank']['images']['small']" [alt]="bank['system_bank']['name']">
                                            </div>
                                            <span>{{bank['system_bank']['name']}}</span>
                                        </div>
                                        <div class="payment-group">
                                            {{bank['account_name']}}
                                        </div>
                                        <div class="payment-number">
                                            {{bank['account_number'] | splitNumber}}
                                        </div>
                                    </div>
                                    }
                                </div>
                            </swiper-slide>
                            }
                        </swiper-container>
                    </div>
                }
                <div class="btn btn-select-rek" (click)="selectPayment()">Pilih Rekening</div>
            </div>
        </div>
    </div>
} -->
@if(showSelectPayment && !showCreateBank && !showError && !loader && !showPin){
<div class="page-change-payment">
    <div class="container-xxl m-auto">
        <div class="row align-items-center container-change-payment py-5 pt-3 mb-5">
            <div class="title-page-small h5">
                {{paymentType === 'company' ? 'Pilih Rekening Tujuan':'Pilih Rekening Anda'}}
            </div>
            <div class="container-input-filter">
                <div ngbDropdown class="position-relative filter-popularity" autoClose="true">
                    <button class="btn-dropdown px-4" (click)="openFilter(currentSelectPaymentType)">
                        <fa-icon [icon]="faSliders"></fa-icon>
                        <span>Filter</span>
                    </button>

                    @if(paymentType === 'company'){
                    <div ngbDropdownMenu class="dropdown-menu select-bank">
                        <div class="d-flex flex-column gap-2">
                            <label ngbDropdownItem for="paymentAll" class="d-flex gap-2"
                                [class.active]="currentSelectPaymentType === 'all'"
                                (click)="onSelectDropdownValue('all')">
                                <!-- <input id="paymentAll" type="checkbox" [checked]="currentSelectPaymentType === 'all'" /> -->
                                Tampilkan Semua
                            </label>
                            <label ngbDropdownItem for="paymentBank" class="d-flex gap-2"
                                [class.active]="currentSelectPaymentType === 'bank'"
                                (click)="onSelectDropdownValue('bank')">
                                <!-- <input id="paymentBank" type="checkbox" [checked]="currentSelectPaymentType === 'bank'" /> -->
                                Bank
                            </label>
                            <label ngbDropdownItem for="paymentEwallet" class="d-flex gap-2"
                                [class.active]="currentSelectPaymentType === 'ewallet'"
                                (click)="onSelectDropdownValue('ewallet')">
                                <!-- <input id="paymentEwallet" type="checkbox" [checked]="currentSelectPaymentType === 'ewallet'" /> -->
                                e-Wallet
                            </label>
                            <label ngbDropdownItem for="paymentCellular" class="d-flex gap-2"
                                [class.active]="currentSelectPaymentType === 'cellular'"
                                (click)="onSelectDropdownValue('cellular')">
                                <!-- <input id="paymentEwallet" type="checkbox" [checked]="currentSelectPaymentType === 'ewallet'" /> -->
                                Celular
                            </label>
                            <label ngbDropdownItem for="paymentQris" class="d-flex gap-2"
                                [class.active]="currentSelectPaymentType === 'qris'"
                                (click)="onSelectDropdownValue('qris')">
                                <!-- <input id="paymentEwallet" type="checkbox" [checked]="currentSelectPaymentType === 'ewallet'" /> -->
                                QRIS
                            </label>
                        </div>
                    </div>
                    } @else{
                    <div ngbDropdownMenu class="dropdown-menu select-bank">
                        <div class="d-flex flex-column gap-2">
                            <label ngbDropdownItem for="paymentAll" class="d-flex gap-2"
                                [class.active]="currentSelectPaymentType === 'all'"
                                (click)="onSelectDropdownValue('all')">
                                <!-- <input id="paymentAll" type="checkbox" [checked]="currentSelectPaymentType === 'all'" /> -->
                                Tampilkan Semua
                            </label>
                            <label ngbDropdownItem for="paymentBank" class="d-flex gap-2"
                                [class.active]="currentSelectPaymentType === 'bank'"
                                (click)="onSelectDropdownValue('bank')">
                                <!-- <input id="paymentBank" type="checkbox" [checked]="currentSelectPaymentType === 'bank'" /> -->
                                Bank
                            </label>
                            <label ngbDropdownItem for="paymentEwallet" class="d-flex gap-2"
                                [class.active]="currentSelectPaymentType === 'ewallet'"
                                (click)="onSelectDropdownValue('ewallet')">
                                <!-- <input id="paymentEwallet" type="checkbox" [checked]="currentSelectPaymentType === 'ewallet'" /> -->
                                e-Wallet
                            </label>
                        </div>
                    </div>

                    }
                </div>
                <div class="input-filter">
                    <input class="form-control" type="text" [(ngModel)]="searchBankInput" placeholder="Cari Rekening"
                        (keyup)="onSearchDropdownValue($event)">
                </div>
            </div>
            @if(paymentType === 'company'){
            <div class="container-list">
                <!-- <swiper-container #swiper appSwiper [config]="config" class="swiper-max-h align-items-center"> -->
                <div class="container-list-slider">
                    @for (bank of searchedOptionsCompany; track bank;) {
                    <div class="swiper-item">
                        <div class="container-payment-bar">
                            @if(bank['qr_image'] || bank['system_bank']['code'] === 'qris'){
                            <div class="payment-bar"
                                [ngClass]="bank['_id'] === currentSelectDataBankAdmin['_id'] ? 'active':''"
                                (click)="selectedBankCompany(bank)">
                                <div class="payment-logo">
                                    <div class="image-payment">
                                        <img src="../../../../template/green/assets/images/qris.png">
                                    </div>
                                </div>
                                <div class="payment-group">
                                    {{bank['account_name']}}
                                    <!-- <div class="payment-number">
                                        -
                                    </div> -->
                                </div>
                            </div>
                            }
                            @if(!bank['qr_image'] && bank['system_bank']['code'] !== 'qris'){
                            <div class="payment-bar"
                                [ngClass]="bank['_id'] === currentSelectDataBankAdmin['_id'] ? 'active':''"
                                (click)="selectedBankCompany(bank)">
                                <div class="payment-logo">
                                    <div class="image-payment">
                                        <img [src]="bank['system_bank']['images']['small']"
                                            [alt]="bank['system_bank']['name']">
                                    </div>
                                </div>
                                <div class="payment-group">
                                    @if(bank['system_bank']['type'] !== 3){
                                        {{bank['account_name']}}
                                    }
                                    <div class="payment-number">
                                        {{bank['account_number'] | splitNumber}}
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    }
                </div>
                <!-- </swiper-container> -->
            </div>
            }@else{
            <div class="container-list" [ngClass]="paymentType !== 'company' && transactionConfig['allow_add_bank_account'] ? 'extra-padding':''">
                <!-- <swiper-container #swiper appSwiper [config]="config" class="swiper-max-h align-items-center"> -->
                <div class="container-list-slider">
                    @for (bank of searchedOptions; track bank;) {
                    <div class="swiper-item">
                        <div class="container-payment-bar">
                            @if(bank['qr_image'] || bank['system_bank']['code'] === 'qris'){
                            <div class="payment-bar"
                                [ngClass]="bank['_id'] === currentSelectDataBankUser['_id'] ? 'active':''"
                                (click)="selectedBankUser(bank)">
                                <div class="payment-logo">
                                    <div class="image-payment">
                                        <img [src]="bank['system_bank']['images']['small']"
                                            [alt]="bank['system_bank']['name']">
                                    </div>
                                    <!-- <span>QRIS</span> -->
                                </div>
                                <div class="payment-group">
                                    {{bank['account_name']}}
                                    <!-- <div class="payment-number">
                                        -
                                    </div> -->
                                </div>
                            </div>
                            }
                            @if(!bank['qr_image'] && bank['system_bank']['code'] !== 'qris'){
                            <div class="payment-bar"
                                [ngClass]="bank['_id'] === currentSelectDataBankUser['_id'] ? 'active':''"
                                (click)="selectedBankUser(bank)">
                                <div class="payment-logo">
                                    <div class="image-payment">
                                        <img [src]="bank['system_bank']['images']['small']"
                                            [alt]="bank['system_bank']['name']">
                                    </div>
                                </div>
                                <div class="payment-group">
                                    {{bank['account_name']}}
                                    <div class="payment-number">
                                        {{bank['account_number'] | splitNumber}}
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    }
                </div>
                <!-- </swiper-container> -->
            </div>
            }
            @if(paymentType !== 'company' && transactionConfig['allow_add_bank_account']){
                <div class="container-buttons">
                    <div class="border-0 outline-0 text-white w-100 btn-create-rek" (click)="selectCreateBankOption()">
                        + Tambah Rekening
                    </div>
                </div>
            }
        </div>
    </div>
</div>
}

@if(showError && !showSelectPayment && !showCreateBank && !loader && !showPin){
<div class="page-change-payment">
    <div class="container-xxl m-auto">
        <div class="row align-items-center container-change-payment d-none d-md-flex py-5 pt-3 mb-5">
            <div class="container-text-error">
                <fa-icon class="icon-error" [icon]="faCircleXmark" [size]="'6x'"></fa-icon>
                <span>Mohon maaf, saat ini anda tidak bisa memilih rekening anda. Mohon hubungi CS kami untuk membantu
                    anda</span>
            </div>
            <div class="btn btn-kembali" (click)="buttonBack()">Kembali</div>
        </div>
    </div>
</div>
}
@if(showCreateBank && !showSelectPayment && !showError && !loader && !showPin){
<div class="page-create-payment">
    <div class="container-xxl m-auto">
        <div class="row align-items-center main-create-bank mb-5">
            <div class="create-payment-container">
                <div class="input-select-payment">
                    <div ngbDropdown class="d-inline-block w-100">
                        <button type="button" class="btn btn-outline-primary w-100 btn-trigger" id="chooseBank" ngbDropdownToggle (click)="openFilterCreateBank(currentSelectCreateBank)">
                            <!-- <fa-icon class="icon-left" [icon]="faMoneyCheckDollar" [size]="'lg'"></fa-icon> -->
                            @if(currentSelectCreateBank === 'bank'){
                            <span class="selected-title">BANK</span>
                            }@else {
                            <span class="selected-title">E-WALLET</span>
                            }
                        </button>
                        <div ngbDropdownMenu style="display: none;"></div>
                        <!-- <div ngbDropdownMenu class="w-100" aria-labelledby="chooseBank">
                            @if(listSelectCreateBank.length > 0){
                            <button ngbDropdownItem (click)="selectBankCreate('bank')">
                                BANK
                                @if(currentSelectCreateBank === 'bank'){
                                <fa-icon class="icon-right" [icon]="faCircleCheck"></fa-icon>
                                }
                            </button>
                            }
                            @if(listSelectCreateEwallet.length > 0){
                            <button ngbDropdownItem (click)="selectBankCreate('ewallet')">
                                E-WALLET
                                @if(currentSelectCreateBank === 'ewallet'){
                                <fa-icon class="icon-right" [icon]="faCircleCheck"></fa-icon>
                                }
                            </button>
                            }
                        </div> -->
                    </div>
                    <div ngbDropdown class="d-inline-block w-100">
                        <button type="button" class="btn btn-outline-primary w-100 btn-trigger" id="chooseBank" ngbDropdownToggle (click)="openFilterSelectCreateBank(currentSelectedCreateBank)">
                            <span class="img">
                                @if(currentSelectedCreateBank['images']){
                                <img [src]="currentSelectedCreateBank['images']['small']"
                                    [alt]="currentSelectedCreateBank['name']">
                                }
                            </span>
                            <span class="bank-name">{{currentSelectedCreateBank['name']}}</span>
                        </button>
                        <div ngbDropdownMenu style="display: none;"></div>
                        <!-- <div ngbDropdownMenu class="w-100" aria-labelledby="chooseBank">
                            <div class="w-100 search-create-bank">
                                <input type="text" class="form-control" autocomplete="off"
                                    [(ngModel)]="inputSearchCreate" (keyup)="onSearchcreateValue($event)"
                                    placeholder="Search Bank.." name="searchBankInput" aria-label="Search Bank.." />
                                <button type="button" class="btn">
                                    <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                                </button>
                            </div>
                            @for (item of searchedCreateOptions; track item;) {
                            <button ngbDropdownItem (click)="selectedCreateBank(item)">
                                <span class="img">
                                    <img [src]="item['images']['small']" [alt]="item['name']">
                                </span>
                                <span class="bank-name">{{item['name']}}</span>
                                @if(currentSelectedCreateBank['_id'] === item['_id']){
                                <fa-icon class="icon-right" [icon]="faCircleCheck"></fa-icon>
                                }
                            </button>
                            }
                        </div> -->
                    </div>
                </div>
                <div class="input-no-rek">
                    <input id="userBankNum" type="text" spellcheck="false"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    class="form-control" [(ngModel)]="inputUserBankNum" [placeholder]="'Nomor Rekening'"
                    name="userBankNum" aria-label="Nomor Rekening" autocomplete="off" />
                    <label for="userBankNum" class="label-input-small">
                        <fa-icon class="icon-left" [icon]="faCreditCard" [size]="'lg'"></fa-icon>
                    </label>
                </div>
                <div class="input-user">
                    
                    <input id="userBankName" type="text" spellcheck="false" class="form-control"
                    [(ngModel)]="inputUserBankName" [disabled]="true" [placeholder]="'Nama Rekening'"
                    name="userBankName" aria-label="Nama Rekening" />
                    <label for="userBankName" class="label-input-small">
                        <fa-icon class="icon-left" [icon]="faUser" [size]="'lg'"></fa-icon>
                    </label>
                </div>
                <div class="text-uppercase border-0 outline-0 text-white w-100 btn-submit-create" [ngClass]="disabledButton? 'pointer-off':''" (click)="createBankAcc()">Tambah Rekening</div>
            </div>
        </div>
    </div>
</div>
}

@if(showPromo && !showCreateBank && !showSelectPayment && !showError && !loader && !showPin){
<div class="page-change-payment">
    <div class="container-xxl m-auto">
        <div class="row align-items-center container-change-promo py-5 pt-3 mb-5">
            <div class="title-page-small h5">
                Pilih Promosi
            </div>
            <div class="container-input-filter">
                <div class="input-filter">
                    <form (ngSubmit)="inputSearchPromo()">
                        <input class="form-control" type="text" [formControl]="searchPromo" placeholder="Cari Promosi">
                        <button type="submit" class="btn">
                            <!-- <fa-icon [icon]="faMagnifyingGlass"></fa-icon> -->
                        </button>
                    </form>
                </div>
                <div ngbDropdown class="position-relative filter-popularity" autoClose="true">
                    <div ngbDropdownMenu class="dropdown-menu select-bank">
                        <div class="d-flex flex-column gap-2">
                            <label ngbDropdownItem for="paymentAll" class="d-flex gap-2"
                                [class.active]="currentSelectPaymentType === 'all'"
                                (click)="onSelectDropdownValue('all')">
                                Tampilkan Semua
                            </label>
                            <label ngbDropdownItem class="d-flex gap-2"
                                [class.active]="currentSelectPaymentType === 'deposit'"
                                (click)="onSelectDropdownValue('deposit')">
                                Deposit Promo
                            </label>
                            <label ngbDropdownItem class="d-flex gap-2"
                                [class.active]="currentSelectPaymentType === 'cashback'"
                                (click)="onSelectDropdownValue('cashback')">
                                Cashback Promo
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-list-dynamic">
                <div class="container-list-promo">
                    @for (item of promoSearch; track $index) {
                    <div class="card-promo" (click)="currentPromo = item; selectPromo()">
                        @if(item['type'] === 'deposit'){
                        <div class="type-promo">DEPOSIT</div>
                        <div class="title-promo deposit">{{item['title']}}</div>
                        <img src="../../../template/green/assets/images/deposit_promo.png" />
                        @if(currentPromo['_id'] === item['_id']){
                        <fa-icon [icon]="faCircleCheck" [size]="'2x'"></fa-icon>
                        }
                        }
                        @if(item['type'] === 'cashback'){
                        <div class="type-promo">CASHBACK</div>
                        <div class="title-promo cashback">{{item['title']}}</div>
                        <img src="../../../template/green/assets/images/cashback_promo.png" />
                        @if(currentPromo['_id'] === item['_id']){
                        <fa-icon [icon]="faCircleCheck" [size]="'2x'"></fa-icon>
                        }
                        }
                    </div>
                    }
                </div>
            </div>
            <!-- <div class="container-buttons">
                <div class="btn btn-create-rek" (click)="showPromo=false">Batal</div>
                <div class="btn btn-select-rek" (click)="selectPromo()">Pilih Promo</div>
            </div> -->
        </div>
    </div>
</div>
<div class="container-pin">
    <div class=""></div>
</div>
}