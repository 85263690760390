@if(currentScreenSize === 'xSmall'){
  <app-header-mobile></app-header-mobile>
}@else {
  <app-header-simple [pageTitle]="'Verifikasi'" />
}
<div class="navigation-title d-md-none d-flex">
  <div class="back-button">
  </div>
  <div class="title">VERIFIKASI</div>
</div>
<div class="container-fluid pin-component pt-3 pt-md-5 pb-5">
  <ng-template #toastTpl let-message="message">
    {{ message }}
  </ng-template>

  <form>
    <div class="main-pin p-4 p-md-5">
      <div class="h2 text-center text-white">
        Masukan pin anda
        <span class="d-md-none d-block">untuk proses verifikasi</span>
      </div>
      <div class="pin-input my-3 my-md-4">
        <input
          id="pin1"
          type="tel"
          maxlength="1"
          tabindex="1"
          autocomplete="off"
          oninput="this.value = this.value.replace(/[^0-9]/g,'');"
          onfocus="this.select();"
          (ngModelChange)="inputPIN(1)"
          [(ngModel)]="formPin.pina"
          autofocus
          [ngModelOptions]="{ standalone: true }"
          class="pin-digit"
        />
        <input
          id="pin2"
          type="tel"
          maxlength="1"
          tabindex="2"
          autocomplete="off"
          oninput="this.value = this.value.replace(/[^0-9]/g,'');"
          onfocus="this.select();"
          (ngModelChange)="inputPIN(2)"
          [(ngModel)]="formPin.pinb"
          [ngModelOptions]="{ standalone: true }"
          class="pin-digit"
        />
        <input
          id="pin3"
          type="tel"
          maxlength="1"
          tabindex="3"
          autocomplete="off"
          oninput="this.value = this.value.replace(/[^0-9]/g,'');"
          onfocus="this.select();"
          (ngModelChange)="inputPIN(3)"
          [(ngModel)]="formPin.pinc"
          [ngModelOptions]="{ standalone: true }"
          class="pin-digit"
        />
        <input
          id="pin4"
          type="tel"
          maxlength="1"
          tabindex="4"
          autocomplete="off"
          oninput="this.value = this.value.replace(/[^0-9]/g,'');"
          onfocus="this.select();"
          (ngModelChange)="inputPIN(4)"
          [(ngModel)]="formPin.pind"
          [ngModelOptions]="{ standalone: true }"
          class="pin-digit"
        />
        <input
          id="pin5"
          type="tel"
          maxlength="1"
          tabindex="5"
          autocomplete="off"
          oninput="this.value = this.value.replace(/[^0-9]/g,'');"
          onfocus="this.select();"
          (ngModelChange)="inputPIN(5)"
          [(ngModel)]="formPin.pine"
          [ngModelOptions]="{ standalone: true }"
          class="pin-digit"
        />
        <input
          id="pin6"
          type="tel"
          maxlength="1"
          tabindex="6"
          autocomplete="off"
          oninput="this.value = this.value.replace(/[^0-9]/g,'');"
          onfocus="this.select();"
          (ngModelChange)="inputPIN(6)"
          [(ngModel)]="formPin.pinf"
          [ngModelOptions]="{ standalone: true }"
          class="pin-digit"
        />
      </div>

      <div class="d-none d-md-flex justify-content-center gap-5">
        <button
          type="button"
          class="btn btn-pinpage btn-logout"
          (click)="logout()"
        >
          LOGOUT
        </button>
        <button
          type="button"
          class="btn btn-pinpage btn-signin"
          (click)="submitPIN()"
          [disabled]="loadingSubmit"
        >
          MASUK
        </button>
      </div>
      <button
        class="d-md-none d-block text-uppercase text-danger btn fw-bold mt-4"
        (click)="logout()"
      >
        Logout
      </button>
      <button
        class="fixed-bottom text-uppercase border-0 outline-0 text-white w-100 btn-sign-mobile d-block d-md-none"
        (click)="submitPIN()"
        [disabled]="loadingSubmit"
      >
        verifikasi
      </button>
    </div>
  </form>
  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</div>
<app-footer class="d-none d-md-block" [data]="" />
