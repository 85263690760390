import { Component, HostListener, Inject, PLATFORM_ID, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClipboardList, faClock, faFileInvoiceDollar, faPenToSquare, faGift, faTrophy, faUser, faPhone, faHeadset, faCircleChevronRight, faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '@componentpath/services/auth.service';
import { isPlatformBrowser, CommonModule, DOCUMENT } from '@angular/common';
import { DataService } from '@componentpath/services/data.service';
@Component({
  selector: 'app-sidebar-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
  ],
  templateUrl: './sidebar-menu.component.html',
  styleUrl: './sidebar-menu.component.scss'
})


export class SidebarMenuComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
  @Inject(DOCUMENT) private doc: Document,
    private auth: AuthService,
    public data: DataService
  ) { }

  @Input() active: string = '';
  faClipboardList: IconProp = faClipboardList;
  faClock: IconProp = faClock;
  faFileInvoiceDollar: IconProp = faFileInvoiceDollar;
  faGift: IconProp = faGift;
  faTrophy: IconProp = faTrophy;
  faUser: IconProp = faUser;
  faPhone: IconProp = faPhone;
  faHeadset: IconProp = faHeadset;
  faCaretLeft: IconProp = faCaretLeft;
  faCaretRight: IconProp = faCaretRight;
  faCircleChevronRight : IconProp = faCircleChevronRight;
  faPenToSquare: IconProp = faPenToSquare;
  username: any = '';
  balance: any = '';
  subscription: Subscription = new Subscription();
  isLoggedin: boolean = false;
  websiteJSONData: any = this.data.websiteJSONData;
  livechatURL: string = '';

  screenWidth: any;

  showSidebar: boolean = true;
  isTablet: boolean = false;
  IDRFormat(value: number) {
    return Intl.NumberFormat('id-ID').format(Math.floor(value));
  }

  subscriptionData() {
    this.subscription.add(
      this.auth.getBalancevalue.subscribe({
        next: (v) => {
          var parts = v.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          this.balance = parts[0];
        },
      })
    );
    this.subscription.add(
      this.auth.getLoginStatus.subscribe({
        next: (v) => {
          this.isLoggedin = v;
        },
      })
    );
  }

  public setLivechatURL(){
    const livechatData = this.websiteJSONData['contact']['livechat'];
    let engineName = (livechatData['engine']).toLowerCase();
    if (engineName === 1 || engineName === 'livechatinc' || engineName === 'livechat' || engineName === 'livechat.com') {
      if (livechatData['code']) {
        this.livechatURL = "https://direct.lc.chat/" + livechatData['code'] + "/" + livechatData['group'];
      }
    }
    if (engineName === 2 || engineName === 'purechat') {
      this.livechatURL = "https://app.purechat.com/w/" + livechatData['pagename'];
    }
    if (engineName === 3 || engineName === 'tawk.to') {
      if (livechatData['pagename']) {
        this.livechatURL = "https://tawk.to/" + livechatData['pagename'];
      } else {
        this.livechatURL = "https://tawk.to/chat/" + livechatData['code'];
      }
    }
  }

  hideBlur(){
    const promotionContainer = this.doc.getElementById('promotion-container');
    promotionContainer?.classList.remove('filter-blur');
  }

  addBlur(){
    const promotionContainer = this.doc.getElementById('promotion-container');
    promotionContainer?.classList.add('filter-blur');
  }


  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.innerWidth;
        this.doc.addEventListener('click', (e)=>{
          if(this.doc.getElementById('sidebar-container')?.contains(e.target as Node)){
            this.showSidebar = true;
            this.addBlur();
          }else{
            if(this.screenWidth >600 && this.screenWidth < 800){
              this.showSidebar = false;
              this.hideBlur();
            }
          }
        })

      if(this.screenWidth >600 && this.screenWidth < 800){
        this.isTablet = true;
        this.showSidebar = false;
        this.hideBlur();
      } else {
        this.isTablet = false;
        this.showSidebar = true;
        this.addBlur();
      }
      this.setLivechatURL();
      this.username = this.auth.userProfileData['login_id'];
      this.subscriptionData();
    }
  }

  hideSidebar(){
    this.hideBlur();
    this.showSidebar = false;
  }


  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
    if(this.screenWidth >600 && this.screenWidth < 800){
      this.isTablet = true;
      this.showSidebar = false;
      this.hideBlur();
    } else {
      this.isTablet = false;
      this.showSidebar = true;
      this.addBlur();
    }
  }



  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
      this.hideBlur();
    }
  }
}
