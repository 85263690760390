import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  OnInit,
  HostListener,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from '@componentpath/services/auth.service';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from '@template/green/header/header.component';
import { RequestService } from '@componentpath/services/request.service';
import { ToastService } from '@componentpath/services/toast.service';
import { ToastComponent } from '@componentpath/parts/toast/toast.component';
import { FooterComponent } from '@template/green/footer/footer.component';
import { DataService } from '@componentpath/services/data.service';
import { HeaderSimpleComponent } from '@template/green/header-simple/header-simple.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HeaderMobileComponent } from '@template/green/mobile/header-mobile/header-mobile.component';

@Component({
  selector: 'app-login-input',
  standalone: true,
  templateUrl: './pin.component.html',
  styleUrl: './pin.component.scss',
  imports: [FormsModule, HeaderMobileComponent, HeaderSimpleComponent, ToastComponent, FooterComponent],
})
export class PinComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  keyboardKey(event: KeyboardEvent) {
    this.valueKeyboard = event.key;
    const eventElement = event.target as Element;
    const numArr = eventElement.id.match(/\d+/g);
    let num = 0;
    if (numArr) {
      num = parseInt(numArr[0]);
    }
    const currentInput = this.documentHTML.getElementById(
      'pin' + num
    ) as HTMLInputElement;
    const prevInput = this.documentHTML.getElementById(
      'pin' + (num - 1)
    ) as HTMLInputElement;
    if (currentInput) {
      if (this.valueKeyboard === 'Tab' || this.valueKeyboard === 'Delete') {
        event.preventDefault();
      }
      if (
        (currentInput.value === '' || currentInput.value === undefined) &&
        prevInput &&
        this.valueKeyboard === 'Backspace'
      ) {
        event.preventDefault();
        prevInput.focus();
      }
    }
  }

  loadingSubmit: boolean = false;
  valueKeyboard: string;

  formPin = {
    pina: '',
    pinb: '',
    pinc: '',
    pind: '',
    pine: '',
    pinf: '',
  };

  currentScreenSize: string = '';

  @ViewChild('toastTpl') toastTpl!: TemplateRef<any>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private auth: AuthService,
    private request: RequestService,
    private data: DataService,
    private router: Router,
    private toastService: ToastService,
    private breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private documentHTML: Document
  ) {
    this.valueKeyboard = '';
    this.checkScreen();
  }
  @ViewChildren('pin') pinInputs!: QueryList<HTMLInputElement>;
  pinValues: string[] = ['', '', '', '', '', ''];

  checkScreen() {
    let displayNameMap = new Map([
      [Breakpoints.XSmall, 'xSmall'],
      ["(min-width: 600px) and (max-width: 799.98px)", 'smallOne'],
      ["(min-width: 800px) and (max-width: 959.98px)", 'smallTwo'],
      ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
    ]);
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      "(min-width: 600px) and (max-width: 799.98px)",
      "(min-width: 800px) and (max-width: 959.98px)",
      "(min-width: 960px) and (max-width: 1365.98px)",
      "(min-width: 1366px)"
    ]).subscribe(result => {
      // console.log(result)
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  inputPIN(i: number) {
    const nextIndex = i + 1;
    const prevIndex = i - 1;
    const currentInput = this.documentHTML.getElementById(
      'pin' + i
    ) as HTMLInputElement;
    const nextInput = this.documentHTML.getElementById(
      'pin' + nextIndex
    ) as HTMLInputElement;
    const prevInput = this.documentHTML.getElementById(
      'pin' + prevIndex
    ) as HTMLInputElement;
    const lastInput = this.documentHTML.getElementById(
      'pin' + 6
    ) as HTMLInputElement;
    if (
      nextInput &&
      currentInput.value !== '' &&
      this.valueKeyboard !== 'Backspace'
    ) {
      nextInput.focus();
    }
    if (prevInput && this.valueKeyboard === 'Backspace') {
      prevInput.focus();
    }
    if (
      currentInput === lastInput &&
      (currentInput.value !== '' || currentInput.value !== undefined) &&
      this.valueKeyboard !== 'Backspace'
    ) {
      // console.log(lastInput.value);
      this.formPin.pinf = lastInput.value;
    }

    if (i === 6) {
      this.submitPIN();
    }
    return;
  }

  submitPIN() {
    this.loadingSubmit = true;
    const userSecurity = this.auth.userSecurityData['pin'].toString();
    const arrFromPIN = Object.values(this.formPin);
    const PINvalue = arrFromPIN.join('');
    if (userSecurity === PINvalue) {
      this.toastService.show({
        template: this.toastTpl,
        classname: 'bg-success text-light',
        delay: 8000,
        context: { message: 'Your PIN Number is Valid' },
      });

      this.loadingSubmit = false;
      this.auth.setPINStatus(false);
      // this.router.navigate(['/']);
    } else {
      this.toastService.show({
        template: this.toastTpl,
        classname: 'bg-danger text-light',
        delay: 8000,
        context: { message: 'Invalid PIN Number' },
      });

      this.loadingSubmit = false;
    }
  }

  public logout(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.request.logout(this.auth.sessionValue).subscribe({
        next: (response) => {
          if (response.code === 200) {
            this.auth.setLogout();
            console.log('Logout success:', response);
          } else {
            console.error('Logout failed:', response.message);
          }
        },
        error: (error) => {
          console.error('Logout error:', error);
        },
        complete: () => {
          if (isPlatformBrowser(this.platformId)) {
            // window.history.go(-3)
            this.router.navigate(['/']);
          }
        },
      });
    }
  }

  ngOnInit(): void {
    // this.getWebsiteInfo();
  }

  ngOnDestroy(): void {
    this.toastService.clear();
  }
}
