import { Component, Inject, PLATFORM_ID,ElementRef, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from "@template/green/header/header.component";
import { FooterComponent } from '@template/green/footer/footer.component';
import { SidebarMenuComponent } from '@template/green/parts/sidebar-menu/sidebar-menu.component';
import { DataService } from '@componentpath/services/data.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { CommonModule, DOCUMENT } from '@angular/common';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { PromotionMobileComponent } from '@template/green/mobile/promotion-mobile/promotion-mobile.component';
import { HeaderSimpleComponent } from '@template/green/header-simple/header-simple.component';
@Component({
  selector: 'app-promotion',
  standalone: true,
  imports: [CommonModule,HeaderSimpleComponent, FooterComponent, SidebarMenuComponent,PromotionMobileComponent],
  templateUrl: './promotion.component.html',
  styleUrl: './promotion.component.scss'
})
export class PromotionComponent {
  @ViewChild('topElement', { static: true }) topElement!: ElementRef;
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
   }

  currentScreenSize: string = '';

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];
  promo: any = null;
  promoJSONData: any = [];

  checkScreen() {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
    ]).subscribe(result => {
      if (result.matches) {
        this.currentScreenSize = 'mobile';
      }
      else{
        this.currentScreenSize = 'desktop';
      }
    });
  }

  public clickBack(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.promo = null;
    }
    this.scrollToTop();
  }

  public promoDetail(id: string): void {
    this.router.navigate([this.router.url.split('?')[0]],{ queryParams: { part: id } });
    if (isPlatformBrowser(this.platformId)) {
      for (const value of this.data.promoJSONData['general'] as any[]) {
        if (value._id == id) {
          this.promo = value;
        }
        this.scrollToTop();
      }
      // this.promo = data;
    }
  }

  scrollToTop(): void {
    setTimeout(() => {
      this.doc.body.scrollTo(0, 0)
    }, 200);
  }

  //get only global false
  public filterPromotion() {
    for (const value of this.data.promoJSONData['general'] as any[]) {
      if (value.global == false) {
        this.promoJSONData.push(value);
      }
    }
  }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      if(params['part'] == 'default'){
        this.promo = null;
      } else {
        this.promoDetail(params['part']);
      }
    });


    if (isPlatformBrowser(this.platformId)) {
      this.filterPromotion();
      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
      this.scrollToTop();
    }
  }
}
