import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { HeaderComponent } from "@template/green/header/header.component";
import { FooterComponent } from '@template/green/footer/footer.component';
import { SidebarMenuComponent } from '@template/green/parts/sidebar-menu/sidebar-menu.component';
import { DataService } from '@componentpath/services/data.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { EventMobileComponent } from '@template/green/mobile/event-mobile/event-mobile.component';
import { HeaderSimpleComponent } from '@template/green/header-simple/header-simple.component';
@Component({
  selector: 'app-event',
  standalone: true,
  imports: [HeaderSimpleComponent, FooterComponent, SidebarMenuComponent, NgOptimizedImage,EventMobileComponent],
  templateUrl: './event.component.html',
  styleUrl: './event.component.scss'
})
export class EventComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }

  currentScreenSize: string = '';

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];
  promo: any = null;
  promoJSONData: any = [];


  checkScreen() {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
    ]).subscribe(result => {
      if (result.matches) {
        this.currentScreenSize = 'mobile';
      }
      else{
        this.currentScreenSize = 'desktop';
      }
    });
  }

  public clickBack(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.promo = null;
    }
  }

  public promoDetail(id: string): void {
    this.router.navigate([this.router.url.split('?')[0]],{ queryParams: { part: id } });
    if (isPlatformBrowser(this.platformId)) {
      for (const value of this.data.promoJSONData['general'] as any[]) {
        if (value._id == id) {
          this.promo = value;
        }
      }
      // this.promo = data;
    }
  }

  //get only global true
  public filterEvent(){
      for (const value of this.data.promoJSONData['general'] as any[]) {
        if (value.global) {
          this.promoJSONData.push(value);
        }
      }
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if(params['part'] == 'default'){
        this.promo = null;
      } else {
        this.promoDetail(params['part']);
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      this.filterEvent();
      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }
}
