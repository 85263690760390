import { CommonModule, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from '@componentpath/services/auth.service';
import { RequestService } from '@componentpath/services/request.service';
import { DataService } from '@componentpath/services/data.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faBars, faCaretDown, faCaretUp, faChartPie, faEdit, faFileLines, faGlobe, faHouse, faMagnifyingGlass, faMoneyBillTransfer, faPhone, faSackDollar, faWallet, faXmark } from '@fortawesome/free-solid-svg-icons';
import { register } from 'swiper/element/bundle';
import dayjs from 'dayjs';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { NewstickerComponent } from '@template/green/parts/newsticker/newsticker.component';
import { SlideshowPromotionComponent } from '@template/green/parts/slideshow-promotion/slideshow-promotion.component';
import { SlugNamePipe } from '@componentpath/pipes/slug-name.pipe';

@Component({
  selector: 'app-header-mobile',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [RouterModule, FontAwesomeModule, NgbDropdownModule, CommonModule, FormsModule, NewstickerComponent, SlideshowPromotionComponent, NgOptimizedImage],
  templateUrl: './header-mobile.component.html',
  styleUrl: './header-mobile.component.scss'
})
export class HeaderMobileComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  pageTitle!: string;
  subscription: Subscription = new Subscription();
  @Input() activeMenu: string = 'home';
  @Input() hideNews: boolean = false;
  faBars: IconProp = faBars;
  faXmark: IconProp = faXmark;
  faClock: IconProp = faClock;
  faHouse: IconProp = faHouse;
  faMagnifyingGlass: IconProp = faMagnifyingGlass;
  faPhone: IconProp = faPhone;
  faCaretDown: IconProp = faCaretDown;
  faCaretUp: IconProp = faCaretUp;
  faGlobe: IconProp = faGlobe;
  faAngleRight: IconProp = faAngleRight;
  faEdit: IconProp = faEdit;
  faFileLines: IconProp = faFileLines;
  faMoneyBillTransfer: IconProp = faMoneyBillTransfer;
  faChartPie: IconProp = faChartPie;
  faSackDollar: IconProp = faSackDollar;
  faWallet: IconProp = faWallet;
  isCollapsed: boolean = true;
  currentProviderCode: string = '';
  showNewsticker: boolean = true;

  websiteJSONData: any = this.data.websiteJSONData;
  promoJSONData: any = [];
  livechatURL: string = '';

  balance: any = undefined;
  dataHeaderGame: any;
  username: string = '';
  searchInput: string = '';
  lang: string = 'Language';
  slotShow: boolean = false;
  casinoShow: boolean = false;
  arcadeShow: boolean = false;
  sportbookShow: boolean = false;
  isLoggedin: boolean = false;
  openMenu: boolean = false;
  openLang: boolean = false;
  showReferral: boolean = true;
  selectedProvider: any = [];

  sideBannerData: any = [
    {
      id: 1,
      title: 'Banner 1',
      image: '../assets/orianna/images/banner1.png',
    },
    {
      id: 2,
      title: 'Banner 2',
      image: '../assets/orianna/images/banner2.png',
    },
    {
      id: 3,
      title: 'Banner 3',
      image: '../assets/orianna/images/banner3.png',
    },
  ];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private auth: AuthService,
    private request: RequestService,
    public data: DataService,
    private router: Router,
    private route: ActivatedRoute
  ){}

  public toggleShow(type: string) {
    if (type === 'slot') {
      this.slotShow = !this.slotShow;
    } else if (type === 'casino') {
      this.casinoShow = !this.casinoShow;
    } else if (type === 'arcade') {
      this.arcadeShow = !this.arcadeShow;
    } else if (type === 'sportbook') {
      this.sportbookShow = !this.sportbookShow;
    }
  }
  chooseLang(langType: string) {
    this.lang = langType;
    this.openLang = false;
  }

  newstickerData: any = this.data.newstickerJSONData;
  categoryProviderData: any = this.data.categoryproviderJSONData;

  time: string =
    dayjs().format('DD-MM-YYYY HH:mm') +
    ' (GMT' +
    dayjs().format('Z)').replace(':00', '');

  navigateToHome() {
    this.router.navigate(['/']);
  }

  groupingArray(arr: any, data: number) {
    const group = [];
    for (let i = 0; i < arr.length; i += data) {
      group.push(arr.slice(i, i + data));
    }
    return group;
  }

  showMenu() {
    this.openMenu = !this.openMenu;
  }
  clickMenu(menu: string) {
    this.selectedProvider = [];
    for (const value of this.categoryProviderData) {
      this.currentProviderCode = value.code_small;
      if (value.code === menu) {
        this.selectedProvider = this.groupingArray(value.providers, 15);
        // console.log(this.selectedProvider);
        break;
      }
    }
    if (this.activeMenu === menu) {
      this.activeMenu = '';
      this.isCollapsed = true;
    } else {
      this.activeMenu = menu;
      if (menu) {
        this.isCollapsed = false;
      } else {
        this.isCollapsed = !this.isCollapsed;
      }
    }
  }

  clickPlay(info: any) {
    let slugProvider = new SlugNamePipe();
    if (this.auth.sessionValue) {
      // this.router.navigate([`/game/${info.game_id}`]);
      this.router.navigate([`/play/${slugProvider.transform(info.game_name)}`], { queryParams: { id: info.game_id } });
    } else {
      this.router.navigate(['/login']);
    }
  }

  navigateToPromotion() {
    this.router.navigate(['/promotion']);
  }

  navigateToReferral() {
    this.router.navigate(['/referral']);
  }


  searchItem() {
    if (this.searchInput) {
      this.router.navigate(['/search'], {
        queryParams: { value: this.searchInput },
      });
    } else {
      this.router.navigate(['/search']);
    }
  }

  public logout(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.request.logout(this.auth.sessionValue).subscribe({
        next: (response) => {
          if (response.code === 200 && response.data.status) {
            this.auth.setLogout();
          } else {
            console.error('Logout failed:', response.message);
          }
        },
        error: (error) => {
          console.error('Logout error:', error);
        },
        complete: () => {
          // window.location.reload();
          // this.router.navigate(['/']);
          // console.log(this.auth.sessionValue);
        },
      });
    }
  }
  public getHeaderGame(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.request.headerGameJSON().subscribe({
        next: (response) => {
          // console.log(response)
          if (response.game_id) {
            this.dataHeaderGame = response;
          }
        },
        error: (error) => {
          console.error(error);
        },
        complete: () => {},
      });
    }
  }

  subscriptionData() {
    this.subscription.add(
      this.auth.getBalancevalue.subscribe({
        next: (v) => {
          let valTrunc = Math.floor(v);
          // var parts = valTrunc.toString().split('.');
          // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          // this.balance = parts.join('.');
          this.balance = valTrunc;
        },
      })
    );
    this.subscription.add(
      this.auth.getLoginStatus.subscribe({
        next: (v) => {
          this.isLoggedin = v;
          if (v && this.auth.userProfileData !== null) {
            for (const [key, value] of Object.entries(this.data.referralRuleJSONData)) {
              if (this.auth.userProfileData['group'] === value.group) {
                this.showReferral = true;
                break;
              } else {
                this.showReferral = false;
              }
            }
          }
        },
      })
    );
  }
  onChange(event: any) {
    this.router.navigate([
      '/search',
      { queryParams: { value: event.target.value } },
    ]);
  }
  redirect(categories: string) {
    this.router.navigate(['/search'], {
      queryParams: {
        categories: encodeURIComponent(JSON.stringify([categories])),
      },
    });
    this.showMenu();
  }

  public setLivechatURL() {
    const livechatData = this.websiteJSONData['contact']['livechat'];
    let engineName = (livechatData['engine']).toLowerCase();
    if (engineName === 1 || engineName === 'livechatinc' || engineName === 'livechat' || engineName === 'livechat.com') {
      if (livechatData['code']) {
        this.livechatURL = "https://direct.lc.chat/" + livechatData['code'] + "/" + livechatData['group'];
      }
    }
    if (engineName === 2 || engineName === 'purechat') {
      this.livechatURL = "https://app.purechat.com/w/" + livechatData['pagename'];
    }
    if (engineName === 3 || engineName === 'tawk.to') {
      if (livechatData['pagename']) {
        this.livechatURL = "https://tawk.to/" + livechatData['pagename'];
      } else {
        this.livechatURL = "https://tawk.to/chat/" + livechatData['code'];
      }
    }
  }

  public redirectToLivechat() {
    window.open(this.livechatURL, '_blank');
  }

  redirectToEvent() {
    this.router.navigate(['/event']);
  }

  //get only global true
  public filterEvent() {
    for (const value of this.data.promoJSONData['general'] as any[]) {
      if (value.global) {
        this.promoJSONData.push(value);
        console.log(value)
      }
    }
  }

  // scroll = (event: any): void => {
  //   const numberUP = event.srcElement.scrollTop;
  //   if(numberUP > 0){
  //     this.showNewsticker = false;
  //   } else {
  //     this.showNewsticker = true;
  //   }
  // };

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // console.log(this.newstickerData);
      this.getHeaderGame();
      this.filterEvent();
      this.setLivechatURL();
      register();
      this.username = this.auth.userProfileData['login_id'];
      this.subscriptionData();
      this.route.queryParams.subscribe((params) => {
        this.searchInput = params['value'];
      });
      // window.addEventListener('scroll', this.scroll, true);
    }
    this.showNewsticker = !this.hideNews;
  }
  ngAfterViewInit(): void {
  }
  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
      // window.removeEventListener('scroll', this.scroll, true);
    }
  }

}
