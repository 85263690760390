import { Component, Inject, PLATFORM_ID, inject } from '@angular/core';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { HeaderComponent } from "@template/green/header/header.component";
import { FooterComponent } from '@template/green/footer/footer.component';
import { SidebarMenuComponent } from '@template/green/parts/sidebar-menu/sidebar-menu.component';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { FormsModule } from '@angular/forms';
import { RequestService } from '@componentpath/services/request.service';
import { faLink, faWallet, faCircleInfo, faCaretDown, faCaretUp, faCaretLeft, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReferralPopupClaimComponent } from '@componentpath/parts/referral-popup-claim/referral-popup-claim.component';
import { ReferralPopupDetailComponent } from '@componentpath/parts/referral-popup-detail/referral-popup-detail.component';
import { RouterModule } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {PageEvent, MatPaginatorModule} from '@angular/material/paginator';
import { DOCUMENT } from '@angular/common';
import dayjs from 'dayjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@template/green/snackbar/snackbar.component';
import { Location } from '@angular/common';
import { RouterExtService } from '@componentpath/services/scripts.service';
@Component({
  selector: 'app-referral-mobile',
  standalone: true,
  imports: [HeaderComponent,MatPaginatorModule, FooterComponent, SidebarMenuComponent, FormsModule, FontAwesomeModule,RouterModule,CommonModule,MatFormFieldModule, MatButtonModule, MatInputModule, MatIconModule, FormsModule],
  templateUrl: './referral-mobile.component.html',
  styleUrl: './referral-mobile.component.scss'
})
export class ReferralMobileComponent {
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private request: RequestService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private location: Location,
    private routerExtService: RouterExtService
  ) { }

  private modalService = inject(NgbModal);

  islogin: boolean = false;
  subscription: Subscription = new Subscription();

  faLink: IconProp = faLink;
  faWallet: IconProp = faWallet;
  faCircleInfo: IconProp = faCircleInfo;
  faCaretDown: IconProp = faCaretDown;
  faCaretUp: IconProp = faCaretUp;
  faCaretLeft: IconProp = faCaretLeft;
  faArrowLeft: IconProp = faArrowLeft;

  footerJSONData: any = this.data.footerJSONData;
  referralJSONData: any = this.data.referralRuleJSONData;
  footerData: any = [];

  referralRule: any = null;
  tableRule: { [key: string]: any }  = {};
  earningRules: { [key: string]: any }  = {};

  referralPlayer : string = '';

  userProfileData: any = null;
  baseUrl: string = '';

  getReferralPlayer: ReferralPlayer | null = null;
  getReferralDownline: any = [];
  getHistory: any = [];

  isVisible: boolean = false;
  showDownline: boolean = false;
  hideRules: boolean = true;

  buttonDisabled = false;

  inputAmount: any = null;
  amountClaim: number = 0;

  earningRulesText: { [key: string]: string } = {
    'max_daily_earning': 'Maks Pendapatan Harian',
    'max_weekly_earning': 'Maks Pendapatan Mingguan',
    'max_monthly_earning': 'Maks Pendapatan Bulanan',
    'max_daily_earning_downline': 'Maks Pendapatan downline Harian',
    'max_weekly_earning_downline': 'Maks Pendapatan downline Mingguan',
    'max_monthly_earning_downline': 'Maks Pendapatan downline Bulanan',
  };


  //table variable
  pageSize = 50;
  showPageSizeOptions = true;
  pageSizeOptions = [50];

  pageIndexHistory = 1;
  lengthHistory = 0;

  pageIndexDownline = 1;
  lengthDownline = 0;

  pageEventHistory!: PageEvent;
  pageEventDownline!: PageEvent;

  maxPageDownline = 0;

  part : string = 'default';

  isLoadingHistory : boolean = false;
  isLoadingDownline : boolean = false;

  prevURL: string = '';

  scrollToTop(): void {
    setTimeout(() => {
      this.doc.body.scrollTo(0, 0)
    }, 200);
  }

  handlePageEvent(e: PageEvent) {
    this.pageEventHistory = e;
    this.pageIndexHistory = e.pageIndex + 1;
    this.fetchClaimedReferral();
  }

  handlePageEventDownline(e: PageEvent) {
    this.pageEventDownline = e;
    this.pageIndexDownline = e.pageIndex + 1;
    this.fetchClaimedReferral();
  }

  goBack(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.prevURL = this.routerExtService.getPreviousUrl();
      // const currentUrl = window.location.href;
      // const referrerUrl = document.referrer;

      // const extractDomain = (url: string) => {
      //   try {
      //     const { hostname } = new URL(url);
      //     return hostname;
      //   } catch (e) {
      //     return null;
      //   }
      // };

      // const currentDomain = extractDomain(currentUrl);
      // const referrerDomain = extractDomain(referrerUrl);

      // if (currentDomain && referrerDomain && currentDomain === referrerDomain) {
      //   this.location.back();
      // } else {
      //   this.router.navigate(['/']);
      // }
      if (this.prevURL === this.router.url) {
        if (this.part === 'claim') {
          this.router.navigate(['/referral']);
        } else {
          this.router.navigate(['/']);
        }
        if (this.part === 'downline') {
          this.router.navigate(['/referral']);
        } else {
          this.router.navigate(['/']);
        }
      } else {
        if (this.part === 'default') {
          // console.log(this.part);
          this.router.navigate(['/']);
        } else {
          // console.log(this.prevURL)
          this.router.navigateByUrl(this.prevURL);
        }
      }
    }
  }

  copyToClipboard(id: string): void {
    if (isPlatformBrowser(this.platformId)) {
      navigator.clipboard.writeText(id).then(() => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: 'ID Key berhasil disalin', type : 'mobile-default' },
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: "notification-default"
        });
      }).catch(err => {
        console.error('Failed to copy ID to clipboard', err);
      });
    }
  }

  popupOpen(name: string): void {
    if (name === 'claim') {
      const activeModal = this.modalService.open(ReferralPopupClaimComponent, { size: 'xl', keyboard: false, centered: true });
      activeModal.componentInstance.max = this.getReferralPlayer?.earning - this.getReferralPlayer?.claimed_earning;
      activeModal.result.then(
        (result) => {
          this.getReferralPlayer = result;
        }
      );
    } else if (name === 'detail') {
      this.modalService.open(ReferralPopupDetailComponent, { size: 'xl', keyboard: false, centered: true });
    }
  }

  updateValueNominal(e: any) {
    let rawNominal = e.split('.').join("");
    this.inputAmount = parseInt(rawNominal);
    this.changeAmount();
  }

  changeAmount() {
    if (this.inputAmount !== undefined) {
      this.amountClaim = parseInt(this.inputAmount);
    }
  }

  toggleDownline(){
    this.router.navigate([this.router.url.split('?')[0]],{ queryParams: { part: 'downline' } });
    this.part = 'downline';
  }

  setMaxClaim() {
    const totalClaimed = this.getReferralPlayer?.earning - this.getReferralPlayer?.claimed_earning;
    // console.log(totalClaimed,this.getReferralPlayer?.earning,this.getReferralPlayer?.claimed_earning)
    this.inputAmount = totalClaimed;
  }

  IDRFormat(value: string): string {
    if(value == null){
      return '';
    }
    const valueWithoutDecimal = value.split('.')[0];

  const numberValue = parseInt(valueWithoutDecimal, 10);

  return Intl.NumberFormat('id-ID').format(numberValue);
  }

  customIDRFormat(value: string): string {
    return Intl.NumberFormat('id-ID').format(parseInt(value));
  }

  dateFormat(value: string) {
    return dayjs(value + 'Z').format('YYYY-MM-DD ');
  }

  dateFormat7(value: string) {
    return dayjs(value + 'Z').add(7, 'hour').format('YYYY-MM-DD');
  }

  hourFormat(value: string) {
    return dayjs(value + 'Z').format('HH:mm:ss');
  }

  popupClose() {
    this.modalService.dismissAll();
  }


  claim(): void {
    this.buttonDisabled = true;
    if (isPlatformBrowser(this.platformId)) {

      if(this.inputAmount == 0){
        return;
      }
      this.request.claimReferral(this.auth.sessionValue, this.inputAmount ,this.userProfileData._id).subscribe({
        next: (v: any) => {
          // console.log(v);
          if (v.code == 200) {
            this.buttonDisabled = false;
            this.fetchClaimedReferral();
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: { message: 'Berhasil Claim Referral', type : 'mobile-success' },
                duration: 3000,
                horizontalPosition: "center",
                verticalPosition: "top",
                panelClass: "notification-success"

            });
          }
        },
        error: (e) => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: { message: 'Gagal Claim Referral', type : 'mobile-error' },
            duration: 3000,
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: "notification-error"

        });
        }
      })
    }
  }

  subscriptionData() {
    this.subscription.add(this.auth.getLoginStatus.subscribe({
      next: (v) => {
        this.islogin = v;
        if (v && this.userProfileData !== null) {
          this.referralRule = null;
          for (const [key, value] of Object.entries(this.data.referralRuleJSONData)) {
            if (this.userProfileData.group === value.group) {
              this.referralRule = value;
              this.tableRule = this.referralRule.rules;
              this.earningRules = this.referralRule.earning_rules;
              this.hideRules = this.referralRule.hide_rules;
              break;
            }
          }
        }
      }
    }));
  }

  toggleReferralClaim() {
    this.router.navigate([this.router.url.split('?')[0]],{ queryParams: { part: 'claim' } });
    this.part = 'claim';
  }

  copyReferralCode(id: any): void {
    navigator.clipboard.writeText(id).then(() => {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'Berhasil Copy Referral Code', type : 'mobile-default' },
        duration: 3000,
        horizontalPosition: "center",
          verticalPosition: "top",
        panelClass: "notification-default"
      });
    }).catch(err => {
      console.error('Failed to copy ID to clipboard', err);
    });
  }

  copyReferral(id: any): void {
    navigator.clipboard.writeText(id).then(() => {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'Berhasil Copy Referral', type : 'mobile-default' },
        duration: 3000,
        horizontalPosition: "center",
          verticalPosition: "top",
        panelClass: "notification-default"
      });
    }).catch(err => {
      console.error('Failed to copy ID to clipboard', err);
    });
  }

  onScrollHistory(event: any) {
    this.checkScrollBottomHistory();
  }

  checkScrollBottomHistory() {
    const element = this.doc.getElementById('scrollableTableHistory');
    if (!element) return;
    const isScrolledBottom = element.scrollTop + element.offsetHeight >= element.scrollHeight-5;
    if (isScrolledBottom && !this.isLoadingHistory) {
      this.pageIndexHistory++;
      this.fetchClaimedReferral();
    }
}

onScrollDownline(event: any) {
  this.checkScrollBottomHistory();
}

checkScrollBottomDownline() {
  const element = this.doc.getElementById('scrollableTableDownline');
  if (!element) return;
  const isScrolledBottom = element.scrollTop + element.offsetHeight >= element.scrollHeight-5;
  if (isScrolledBottom && !this.isLoadingHistory && (this.pageIndexDownline+1 <= this.maxPageDownline)) {
    this.pageIndexDownline++;
    this.fetchReferralDownline();
  }
}

  numberFormat(value: number) {
    return Intl.NumberFormat('id-ID').format(value);
  }

  fetchReferralPlayer() {
    this.request.getReferralPlayer(this.auth.sessionValue, this.userProfileData._id).subscribe({
      next: (v) => {
        this.getReferralPlayer = {
          _id: v.data._id,
          referral_code: v.data.referral_code,
          earning: v.data.earning,
          claimed_earning: v.data.claimed_earning,
          total_downline : v.data.total_downline
        };
        this.scrollToTop();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => { }
    });
  }

  fetchClaimedReferral() {
    this.request.claimedReferral(this.auth.sessionValue, { "page": this.pageIndexHistory, "limit": this.pageSize }).subscribe({
      next: (v) => {
        this.lengthHistory = v.data.total_records;
        if(this.getHistory == null){
          this.getHistory = v.data.records;
        } else {
          this.getHistory.push(...v.data.records);
        }
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => { }
    });
  }

  fetchReferralDownline() {
    this.request.getReferralDownline(this.auth.sessionValue, this.userProfileData._id,{ page: this.pageIndexDownline, limit: this.pageSize }).subscribe({
      next: (v) => {
        this.lengthDownline = v.data.total_records;
        this.maxPageDownline = v.data.max_page;
        if(this.getReferralDownline == null){
          this.getReferralDownline = v.data.records;
        } else {
          this.getReferralDownline.push(...v.data.records);
        }
        // console.log(this.getReferralDownline);
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => { }
    });
  }



  ngOnInit(): void {
    this.router.navigate([this.router.url.split('?')[0]],{ queryParams: { part: 'default' } });
    this.activatedRoute.queryParams.subscribe(params => {
      if(params['part'] == 'claim'){
        this.part = 'claim';
      }
      else if(params['part'] == 'downline'){
        this.part = 'downline';
      } else{
        this.part = 'default';
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      this.referralJSONData = this.data.referralRuleJSONData;

      for (const [key, value] of Object.entries(this.data.referralRuleJSONData)) {
        if (value.group_rank === 1) {
          this.referralRule = value;
          this.tableRule = this.referralRule.rules;
          this.earningRules = this.referralRule.earning_rules;
          this.hideRules = this.referralRule.hide_rules;
          break;
        }
      }
      this.subscriptionData();

      // console.log(this.referralRule);

      const parsedUrl = new URL(window.location.href);
      this.baseUrl = parsedUrl.origin;

      this.userProfileData = this.auth.userProfileData;
      this.fetchReferralPlayer();
      this.fetchClaimedReferral();
      this.fetchReferralDownline();

      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }

}

export interface ReferralPlayer {
  _id: string;
  earning: any;
  claimed_earning: any;
  referral_code: string;
  total_downline : number;
}

