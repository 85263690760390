<div class="header-container">
  <div class="header-container-inner">
    <div class="header-container-inner-wrapper">
      <div class="header-middle">
        <div class="header-middle-center">
          <!-- <a href="/">
            <img [src]="websiteJSONData.header.logo" />
          </a> -->
          <div class="d-flex">
            <form (keydown.enter)="searchItem()" [class.invisible]="openMenu">
              <input
                type="text"
                class="form-control"
                placeholder="Search games.."
                [(ngModel)]="searchInput"
                name="searchInput"
                aria-label="Search games.."
              />
              <button type="button" class="btn" (click)="searchItem()">
                <fa-icon [icon]="faMagnifyingGlass" type="submit"></fa-icon>
              </button>
            </form>

            <!-- @if(openMenu){
            <button class="bttn-close" (click)="showMenu()">
              <fa-icon [icon]="faXmark"></fa-icon>
            </button>
            } @else {
            <button class="btn pt-0 d-block d-md-none" (click)="showMenu()">
              <img
                src="../template/green/assets/images/ic-hamburger-menu.png"
              />
            </button>
            } -->
          </div>
        </div>
        @if(isLoggedin){
        <div class="header-middle-right-loggedin d-flex">
          <a [routerLink]="['/profile']">
            <button class="d-flex align-items-center justify-content-center user-profile-button btn-info gap-2" >
              <div>
                <p class="text-nowrap text-uppercase">{{ username }}</p>
                @if(balance == undefined){
                <p class="text-nowrap fw-bold">Loading...</p>
                } @else {
                <p class="text-nowrap fw-bold">IDR {{balance | currency:' ' : 'symbol' : '1.0-0'}}</p>
                }
              </div>
              <img
                src="../template/green/assets/images/user-dummy.png"
                class="profile"
              />
            </button>
          </a>
          <a [routerLink]="['/withdrawal']">
            <button
              class="btn-info d-flex align-items-center justify-content-center gap-1"
            >
              <img src="../template/green/assets/images/ic-withdraw.png" class="custom-image-top-header"/>
              <div class="hide-text-dp-wd">WITHDRAW</div>
            </button>
          </a>
          <a [routerLink]="['/deposit']">
            <button
              class="btn-deposit d-flex align-items-center justify-content-center gap-1"
            >
              <img src="../template/green/assets/images/ic-deposit.png" class="custom-image-top-header"/>
              <div class="hide-text-dp-wd">DEPOSIT</div>
            </button>
          </a>
          <button class="btn-logout" (click)="logout()">LOGOUT</button>
        </div>
        } @else{
        <div class="header-middle-right">
          <a [routerLink]="['/login']">
            <button class="btn-login">LOGIN</button>
          </a>
          <a [routerLink]="['/register']">
            <button class="btn-register">REGISTER</button>
          </a>
        </div>
        }
        <!-- mobile -->
      </div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="megamenu">
      <div class="container-xxl">
        <div class="row">
          <div class="col-9 swiper-max-h">
            <div class="row">
              <div>
                <swiper-container
                  appSwiper
                  [config]="config"
                  class="swiper-max-h align-items-center"
                >
                  @for (item of selectedProvider; track item.code;) {
                  <swiper-slide class="swiper-item">
                    <div class="container-provider-menu">
                      @for(provider of item; track provider.code;){
                      <a [routerLink]="['/search']" [queryParams]="{'categories':(currentProviderCode | enCodeUri),'providers':(provider['code'] | enCodeUri)}">
                        <img
                          ngSrc="{{provider.image_url}}"
                          [title]="provider.name"
                          [alt]="provider.name"
                          width="150"
                          height="150"
                          loading="eager"
                        />
                      </a>
                      }
                    </div>
                    <div class="swiper-lazy-preloader"></div>
                  </swiper-slide>
                  }
                </swiper-container>
              </div>
            </div>
          </div>
          <div class="col-3 swiper-max-h d-flex flex-column promo-container" [ngClass]="{ 'justify-content-between': sideBannerData.length === 4, 'justify-content-start': sideBannerData.length < 4 }" >
            @for (item of promoJSONData; track item.id;) {
            <div
              [ngClass]="{
                'mb-4': promoJSONData.length < 4
              }"
            >
            <a [routerLink]="['/event']" [queryParams]="{ part: item._id }">
              <img [src]="item.thumbnail_url" [title]="item.title" [alt]="item.title" />
            </a>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="menu-top d-block">
    <div class="container-xxl menu-top-left">
      <div>
        <a [routerLink]="['/']">
          <img [src]="websiteJSONData.header.logo" />
        </a>
      </div>
      <div
        class="menu-top-left-item"
        [class.menu-top-left-item-active]="activeMenu === 'slots'"
        (click)="clickMenu('slots')"
      >
        <a> Slot </a>
        <img src="../template/green/assets/images/ic-chevron-down.png" />
      </div>
      <div
        class="menu-top-left-item"
        [class.menu-top-left-item-active]="activeMenu === 'live-casino'"
        (click)="clickMenu('live-casino')"
      >
        <a> casino </a>
        <img src="../template/green/assets/images/ic-chevron-down.png" />
      </div>
      <div
        class="menu-top-left-item"
        [class.menu-top-left-item-active]="activeMenu === 'fish-hunter'"
        (click)="clickMenu('fish-hunter')"
      >
        <a> arcade </a>
        <img src="../template/green/assets/images/ic-chevron-down.png" />
      </div>
      <div
        class="menu-top-left-item"
        [class.menu-top-left-item-active]="activeMenu === 'sportbook'"
        (click)="clickMenu('sportbook')"
      >
        <a> sportbook </a>
        <img src="../template/green/assets/images/ic-chevron-down.png" />
      </div>
      <div
        class="menu-top-left-item d-none d-lg-block hide-tablet-600 hide-tablet-800"
        [class.menu-top-left-item-active]="activeMenu === 'crash'"
      >
        <a [routerLink]="['/search']" [queryParams]="{'tags':('CRASH' | enCodeUri)}"> crash </a>
        <!-- <img src="../template/green/assets/images/ic-chevron-down.png" /> -->
      </div>
      <!-- <div class="menu-top-left-item d-none d-lg-block hide-tablet-600 hide-tablet-800" [class.menu-top-left-item-active]="activeMenu === 'togel'" >
        <a [routerLink]="['/search']" [queryParams]="{'categories':('LT' | enCodeUri)}"> togel </a>
      </div> -->
      <div
        class="menu-top-left-item d-none d-lg-block hide-tablet-600 hide-tablet-800"
        [class.menu-top-left-item-active]="activeMenu === 'esport'"
      >
        <a [routerLink]="['/search']" [queryParams]="{'categories':('ES' | enCodeUri)}"> esport </a>
        <!-- <img src="../template/green/assets/images/ic-chevron-down.png" /> -->
      </div>
      <div
        class="menu-top-left-item hide-tablet-600"
        [class.menu-top-left-item-active]="activeMenu === 'promosi'"
        (click)="navigateToPromotion()"
      >
        <a> promosi </a>
      </div>
      <div
        class="menu-top-left-item hide-tablet-600"
        [class.menu-top-left-item-active]="activeMenu === 'referral'"
        (click)="navigateToReferral()"
      >
        <a> referral </a>
      </div>
      @if(websiteJSONData['contact']['livechat']){
        <div
          class="menu-top-left-item hide-menu-livechat hide-tablet-600"
          [class.menu-top-left-item-active]="activeMenu === 'livechat'"
        >
          <a> livechat </a>
        </div>
      }
    </div>
  </div>

  @if (pageTitle) {
  <!-- <div
    class="header-container-mobile position-absolute top-0 d-flex d-md-none pt-3"
    *ngIf="!isLoggedin"
  >
    <button class="btn outline-0 border-0 ml-1" (click)="navigateToHome()">
      <img src="../template/green/assets/images/icon-back.png" />
    </button>
    <div class="h4 text-uppercase fw-bold text-white text-center header-title m-0">
      {{ pageTitle }}
    </div>
  </div> -->
  } @else {
    @if(showNewsticker){
      <div class="menu-bottom">
        <div class="header-bottom-center">
          <div class="latest-news container-xxl p-0">
            <div class="latest-news-left">
              <img
                src="../template/green/assets/images/ic-bullhorn.png"
                width="12"
                height="10"
              />
            </div>
            <div class="latest-news-right">
              <div class="latest-news-right-inner">
                <app-newsticker [data]="newstickerData"></app-newsticker>
              </div>
            </div>
            <a class="container-event-click" [routerLink]="['/event']">
              <div class="event-button-container" >
                <img class="trophy" src="../template/green/assets/images/header/trophy-uno.gif" />
                <img class="event" src="../template/green/assets/images/header/EVENT.png" />
              </div>
            </a>
          </div>
        </div>
      </div>
    }
  }
  @if(openMenu){
  <div class="hamburger-mobile">
    @if(isLoggedin){
    <div class="position-relative top-menu">
      <div class="user-info">
        <img
          src="../../../template/green/assets/images/status-gold.png"
          class="img-status"
        />
        <div class="detail-info">
          <div>
            <p class="username">{{ username }}</p>
            @if(balance == undefined){
            <p class="balance">Loading...</p>
            } @else {
            <p class="balance">IDR {{balance | currency:' ' : 'symbol' : '1.0-0'}}</p>
            }
          </div>
          <img
            src="../../../template/green/assets/images/label-gold-mobile.png"
            width="40px"
            height="32px"
          />
        </div>
        <div class="side-btn">
          <button>
            <fa-icon [icon]="faEdit"></fa-icon>
            <span class="m-0 p-0">EDIT PROFILE</span>
          </button>
        </div>
      </div>
      <div class="submenu">
        <div>
          <img
            src="../../../template/green/assets/images/ic-deposit.svg"
            alt="Deposit"
          />
          <a [routerLink]="['/deposit']" class="hide-text-dp-wd">Deposit </a>
        </div>
        <div>
          <img
            src="../../../template/green/assets/images/ic-withdraw.svg"
            alt="Withdraw"
          />
          <a [routerLink]="['/withdrawal']" class="hide-text-dp-wd">Withdraw</a>
        </div>F
        <div>
          <img
            src="../../../template/green/assets/images/ic-statement.svg"
            alt="Statement"
          />
          <a [routerLink]="['/statement']">Statement</a>
        </div>
        <div>
          <img
            src="../../../template/green/assets/images/ic-transaction.svg"
            alt="Transaction"
          />
          <a [routerLink]="['/transaction']">Transaction</a>
        </div>
        <div>
          <img
            src="../../../template/green/assets/images/ic-saldo.svg"
            alt="Saldo"
          />
          <a [routerLink]="['/saldo']">Saldo</a>
        </div>
      </div>
    </div>
    } @else {
    <div class="auth-btn-container d-flex m-0 p-0">
      <a [routerLink]="['/login']">
        <button class="btn-login">LOGIN</button>
      </a>
      <a [routerLink]="['/register']">
        <button class="btn-register">REGISTER</button>
      </a>
    </div>
    }
    <div class="position-relative d-flex flex-column language">
      <button (click)="openLang = !openLang">
        <div class="d-flex">
          <fa-icon [icon]="faGlobe"></fa-icon>
          <p class="m-0 p-0">{{ lang }}</p>
        </div>
        <fa-icon [icon]="faCaretDown"></fa-icon>
      </button>
      @if(openLang){
      <div class="lang-dropdown">
        <span (click)="chooseLang('Indonesia')">Indonesia</span>
        <span (click)="chooseLang('English')">English</span>
      </div>
      }
    </div>
    @if(websiteJSONData.configuration.apk_url){
    <div class="apk">
      <p>Akses Lebih Cepat & Mudah Download Aplikasi Kami Sekarang!</p>
      <a [href]="websiteJSONData.configuration.apk_url" class="btn-download">
        Download Now</a
      >
    </div>
    }
    <div class="list-promotion">
      <app-slideshow-promotion [data]="sideBannerData" />
    </div>
    <div class="list-menu-middle">
      <div class="menu-card">
        <p class="title">Promosi</p>
        <p class="desc">Dapatkan promosi terbaik untuk kemenangan maksimal</p>
        <img
          src="../../../template/green/assets/images/mobile-promosi.png"
          alt="Promosi"
        />
      </div>
      <div class="menu-card">
        <p class="title">Referral</p>
        <p class="desc">Ajak temanmu dan dapatkan keuntungannya</p>
        <img
          src="../../../template/green/assets/images/mobile-referral.png"
          alt="Referral"
        />
      </div>
      <div class="menu-card">
        <p class="title">Event</p>
        <p class="desc">Lihat event terbaru dengan hadiah terbesar</p>
        <img
          src="../../../template/green/assets/images/mobile-event.png"
          alt="Event"
        />
      </div>
      <div class="menu-card">
        <p class="title">Contact</p>
        <p class="desc">Hubungi kami untuk segala pertanyaan kamu</p>
        <img
          src="../../../template/green/assets/images/mobile-contact.png"
          alt="Contact"
        />
      </div>
    </div>
    <div class="list-menu-bottom">
      <a class="detail" [routerLink]="['/']">
        <p>Beranda</p>
        <fa-icon [icon]="faAngleRight"></fa-icon>
      </a>
      <button class="detail" (click)="redirect('SL')">
        <p>Slot</p>
        <fa-icon [icon]="faAngleRight"></fa-icon>
      </button>
      <button class="detail" (click)="redirect('LC')">
        <p>Live Casino</p>
        <fa-icon [icon]="faAngleRight"></fa-icon>
      </button>
      <button class="detail" (click)="redirect('FH')">
        <p>Arcade</p>
        <fa-icon [icon]="faAngleRight"></fa-icon>
      </button>
      <button class="detail" (click)="redirect('FH')">
        <p>Lottery</p>
        <fa-icon [icon]="faAngleRight"></fa-icon>
      </button>
      <button class="detail" (click)="redirect('ES')">
        <p>Esports</p>
        <fa-icon [icon]="faAngleRight"></fa-icon>
      </button>
      <a class="detail" [routerLink]="['/']">
        <p>Providers</p>
        <fa-icon [icon]="faAngleRight"></fa-icon>
      </a>
      <button
        class="detail logout"
        [class.d-none]="!isLoggedin"
        (click)="logout()"
      >
        <p>Sign Out</p>
        <fa-icon [icon]="faAngleRight"></fa-icon>
      </button>
    </div>
  </div>
  }
</div>
